@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.header {
  border-top: 0;
  padding: 12px;
  padding-bottom: 4px;
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);

  .headerText {
    @include flexbox();
    @include justify-content(flex-start);
    @include align-items(center);
  }

  .title {
    font-size: 13px;
    font-weight: 700;
    margin-left: 6px;
    text-align: left;
  }

  .date {
    font-size: 12px;
    margin-left: 6px;
    text-align: left;
  }
}

.counts {
  @include flexbox();
  @include justify-content(flex-end);
  @include align-items(center);
  button {
    @include inline-flexbox();
    @include justify-content(center);
    @include align-items(center);
    padding-left: 4px;
    padding-right: 4px;
    border: none !important;
    box-shadow: none !important;

    &::after,
    &:active,
    &:hover {
      border: none !important;
      @include prefix(animation, none !important);
    }

    > .iconCircle {
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: $primary;

      svg {
        font-size: 12px;
        color: #ffffff !important;
        fill: #ffffff !important;
      }
    }

    > span {
      margin-left: 2px !important;
    }
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.notificationsContainer {
  @include zDepth1;
}

.notificationsDrawer {
  :global(.ant-drawer-content-wrapper) {
    width: 400px;
  }

  :global(.ant-drawer-content-wrapper) {
    @include mq("sm") {
      width: 100%;
    }
  }
}

.notificationsHeader {
  @include flexbox();
  height: 64px;
  align-items: center;
  background: $primary;

  :global(button) {
    margin: 0 12px;
    border: none;
    outline: none;
    box-shadow: none;
  }
}

.notificationsTitle {
  font-size: 1rem;
  text-align: center;
  @include flex-grow(1);
  margin-bottom: 0;
  color: #ffffff;
}

.notificationsItemsListItem {
  padding: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #ffffff;
  position: relative;

  &:global(.read) {
    background: rgb(245, 245, 245);
  }

  :global(.ant-list-item-meta) {
    padding: 12px 0;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: rgb(227, 239, 252);
    }
  }

  :global(.ant-avatar.ant-avatar-circle) {
    position: absolute;
    top: 50%;
    left: 50%;
    @include prefix(transform, translate(-50%, -50%));
    background: rgba(0, 0, 0, 0.22);

    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
  }

  :global(.ant-list-item-meta-avatar) {
    position: relative;
    width: 48px;
    @include align-self(center);
    height: 48px;
    margin-left: 10px;
    margin-right: 12px;
  }

  :global(.ant-list-item-meta-title) {
    @include flexbox();
    padding-right: 12px;
    @include align-items(baseline);
    b {
      @include flex-grow(1);
    }
  }

  svg {
    color: #ffffff !important;
  }
}

.notificationsItemsContainer {
  height: calc(100% - 64px - 34px);
  width: 100%;

  :global(.ant-list-item-meta-description) {
    @include mq("sm", min) {
      display: block;
    }
  }
}

.notificationsItemsEmptyContainer {
  height: 100vh;
  width: 100%;
}

.notificationsItemsCenterEmptyMessage {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  :global(i) {
    text-align: center;
    font-size: 8rem;
    color: #828282;
  }
}

.notificationsItemsCenterEmptyText {
  text-align: center;
  font-size: 1.1rem;
  color: #828282;
}

@import "Styles/variables.scss";

.listItemHeader {
  &:global(.ant-list-item) {
    padding-left: 12px;
    padding-right: 12px;
    background: $menuHeader;
    color: #ffffff;
    :global(.ant-list-item-meta-title) {
      color: #ffffff;
    }
  }
}

.listItem {
  &:global(.ant-list-item) {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.listSpacer {
  border-top: 1px solid #f0f0f0;
}

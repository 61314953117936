@import "Styles/mixins.scss";

.headerRow {
  &:global(.z-depth-2) {
    margin-left: -24px !important;
    margin-right: -24px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

.checkbox {
  height: calc(100% - 12px);
  > :global(.ant-col) {
    height: 100%;
    > :global(.ant-form-item-control-input) {
      height: 100%;
    }
  }

  :global(.ant-checkbox) {
    margin-left: calc(50% - 6px);
    border-radius: 50%;

    @include mq(sm) {
      margin-left: 50%;
    }

    &:hover::after {
      border-radius: 50%;
    }
  }

  input {
    height: 24px !important;
    width: 24px !important;
    border-radius: 50%;
    outline: none;
  }

  :global(.ant-checkbox-inner) {
    height: 24px;
    width: 24px;
    border-radius: 50%;

    &::after {
      left: 30%;
    }
  }

  :global(.ant-checkbox-indeterminate) {
    :global(.ant-checkbox-inner) {
      &::after {
        border-radius: 50%;
        left: 50%;
      }
    }
  }
}

@import "Styles/mixins.scss";

.scopesContainer {
  background-color: #ffffff;
  border-top: 1px solid #d9d9d9;
  @include zDepth1;
  margin-bottom: 8px;
  border-radius: 4px;
}

.noScopes {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(column);

  height: calc(100% - 80px);
  color: rgba(0, 0, 0, 0.65);
}

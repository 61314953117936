@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.drawer {
  &.modal {
    :global(.ant-drawer-content) {
      border-radius: 4px;
    }
  }
}

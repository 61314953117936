@import "Styles/mixins.scss";

.reviewSection {
  height: 188px;
  @include prefix(transition, height 0.3s);

  &.collapsed {
    height: 32px;

    .reviewSectionChild {
      .collapseTrigger {
        > svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .reviewSectionChild {
    height: 100%;
    overflow: hidden;

    .collapseTrigger {
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      z-index: 1;

      > svg {
        @include prefix(transition, transform 0.3s);
        transform: rotate(0deg);
      }
    }

    .reviewNotes {
      opacity: 1;
      @include prefix(transition, opacity 0.3s);

      &.collapsed {
        opacity: 0;
      }
    }
  }
}

@import "Styles/variables.scss";

.mediaContainer {
  white-space: nowrap;
  overflow: auto;
  width: 100%;
  padding: 4px;
  background: $dark;
  border-radius: 4px;
}

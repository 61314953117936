.tabs {
  height: 100%;

  :global(.ant-tabs-tab) {
    padding-top: 12px;
    padding-bottom: 2px;
  }

  :global(.ant-tabs-content-holder) {
    height: 100%;
  }

  :global(.ant-tabs-content) {
    height: 100%;
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.updateCommentContainer {
  margin-bottom: 12px;

  .updateComment {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    background: $dark;
    color: rgba(255, 255, 255, 0.85);
    border-radius: 20px;
    padding: 4px 16px;
    font-size: 11px;

    .content > span {
      white-space: pre-wrap;
    }

    b {
      color: #ffffff;
    }
  }

  .imageRow {
    @include flexbox();
    @include justify-content(flex-start);
    @include align-items(center);
    padding-left: 46px;
    padding-top: 4px;
  }

  .actionRow {
    @include flexbox();
    @include justify-content(flex-end);
    @include align-items(center);
    font-size: 12px;

    .timeStamp {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.replyToComment {
  :global(.ant-comment-content-author-name) {
    width: 100%;
    padding-right: 0;
  }

  .replyTo {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    height: 40px;
    background: rgba(16, 142, 233, 0.3);
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -2px;
    padding-left: 10px;
    border-left: 6px solid $primary;
    margin-bottom: 6px;
    border-radius: 6px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.55);

    .replyToIcon {
      @include flexbox();
      @include align-items(center);
      svg {
        margin-right: 4px;
      }
    }

    .replyToImage {
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: center !important;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      width: 25%;
      height: 40px;
    }
  }
}

.reply {
  margin-top: 10px;
  padding-left: 45px;

  :global(.ant-comment) {
    :global(.ant-comment-inner) {
      padding: 0;
    }
    :global(.ant-comment-content) {
      background: rgb(240, 242, 245);
      border-radius: 14px;
      padding: 4px 14px;
      :global(.ant-comment-content-author) {
        margin-bottom: 0;
      }
      :global(.ant-comment-content-author-name) {
        font-weight: bolder;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}

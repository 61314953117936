@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.activity {
  margin-bottom: 8px !important;
  @include zDepth1;
  .body {
    padding: 4px 12px;
    .content > span {
      white-space: pre-wrap;
    }
  }
}

.comment {
  padding-bottom: 12px;
  &:last-child {
    padding-bottom: 0;
  }

  &._global {
    :global(.ant-comment) {
      :global(.ant-comment-content) {
        background: #424242;
      }
    }
  }

  :global(.ant-comment) {
    :global(.ant-comment-inner) {
      padding: 0;
    }
    :global(.ant-comment-content) {
      background: rgb(240, 242, 245);
      border-radius: 14px;
      padding: 4px 14px;
      :global(.ant-comment-content-author) {
        margin-bottom: 0;
      }
      :global(.ant-comment-content-author-name) {
        font-weight: bolder;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }

  &.replyToComment {
    :global(.ant-comment-content-author-name) {
      width: 100%;
      padding-right: 0;
    }

    .replyTo {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);
      height: 40px;
      background: rgba(16, 142, 233, 0.3);
      margin-left: -10px;
      margin-right: -10px;
      margin-top: -2px;
      padding-left: 10px;
      border-left: 6px solid $primary;
      margin-bottom: 6px;
      border-radius: 6px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.55);

      .replyToIcon {
        @include flexbox();
        @include align-items(center);
        svg {
          margin-right: 4px;
        }
      }

      .replyToImage {
        // background-repeat: no-repeat !important;
        // background-size: cover !important;
        // background-position: center !important;
        overflow: hidden;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        width: 25%;
        height: 40px;
      }
    }
  }

  .reply {
    margin-top: 10px;
    padding-left: 45px;
  }
}

.imageRow {
  @include flexbox();
  @include justify-content(flex-start);
  @include align-items(center);
  padding-left: 46px;
  padding-top: 4px;
}

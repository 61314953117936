@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.lineItem {
  padding-left: 0;
  padding-right: 0;

  :global(.ant-form-item) {
    margin: 0;
  }
}

div.noBorder {
  margin-bottom: 0;
  :global(.ant-form-item-explain) {
    min-height: 0px;
  }

  &.xs {
    margin-bottom: 12px;
  }
}

.lineItemRow {
  border-radius: 4px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background: transparent;
  @include prefix(transition, background 0.3s);
  &:hover {
    background: $rowHoverSelect;
  }
}

.rowSelected {
  border-radius: 4px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background: $rowHoverSelect;
}

.checkbox {
  height: calc(100% - 12px);
  > :global(.ant-col) {
    height: 100%;
    > :global(.ant-form-item-control-input) {
      height: 100%;
    }
  }

  :global(.ant-checkbox) {
    margin-left: calc(50% - 6px);
    border-radius: 50%;

    @include mq(sm) {
      margin-left: 50%;
    }

    &:hover::after {
      border-radius: 50%;
    }
  }

  input {
    height: 24px !important;
    width: 24px !important;
    border-radius: 50%;
    outline: none;
  }

  :global(.ant-checkbox-inner) {
    height: 24px;
    width: 24px;
    border-radius: 50%;

    &::after {
      left: 30%;
    }
  }
}

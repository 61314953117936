@import "Styles/mixins.scss";

.tableFilter {
  &:global(.ant-list) {
    background: rgb(245, 245, 245);
    border-top: none !important;
    :global(.ant-list-item) {
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 24px;
      padding-right: 24px;

      @include mq("xs") {
        padding-left: 12px !important;
        padding-right: 12px !important;
      }
    }
  }
}

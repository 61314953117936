.moldContainer {
  padding: 12px 24px;
}

.moldRow {
  text-align: right;
  font-weight: 400;
}

.totalRow {
  font-weight: 700;
  padding: 13px 24px;
  border-top: 2px solid rgba(139, 99, 99, 0.87);
}

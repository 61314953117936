@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.activity {
  &:global(.ant-card) {
    margin-bottom: 16px;
    overflow: hidden;
    cursor: pointer;
    :global(.ant-card-cover) {
      min-height: 160px;
      max-height: 160px;
      overflow: hidden;
      background: $dark;

      img,
      object {
        object-fit: cover;
      }
    }
  }
}

.pdf {
  height: 100%;
  width: 100%;
  padding: 8px;
  min-height: 160px;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(column);

  > img {
    max-width: 45px;
    display: block;
  }

  > button {
    display: block;
  }

  > div {
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    margin-top: 8px;
  }
}

.image {
  min-height: 160px;
  height: 100%;
  width: 100%;
}

.deleteText {
  color: $lost;
}

.fab {
  &:global(.ant-btn) {
    height: 56px;
    width: 56px;
    z-index: 2001;
    @include inline-flexbox();
    @include justify-content(center);
    @include align-items(center);
    position: absolute;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
    cursor: pointer;
    outline: none;
    padding: 0;
    -webkit-user-drag: none;
    font-weight: bold;
    font-size: 18px;
    z-index: 2;
    right: 24px;
    bottom: 77px;
    @include prefix(transition, bottom 0.3s);
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.imageViewer {
  top: 0;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 0;
  height: 100%;
  width: 100%;
  max-width: 100% !important;

  :global(.ant-modal-content) {
    width: 100%;
    height: 100%;
  }

  :global(.ant-modal-body) {
    width: 100%;
    height: 100%;
    background: $dark;
    position: relative;
    padding-top: 1px !important;
    margin-top: -1px;
  }
}

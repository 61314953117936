@import "Styles/mixins.scss";

.tableBody {
  &:global(.ant-list) {
    height: calc(100% - 57px - 37px - 65px);
    position: absolute;
    bottom: 0;
    width: 100%;
    @include prefix(transition, width 0.2s);
    border: none !important;
    background: #ffffff;
    @include flex-grow(1);

    :global(.ant-spin-nested-loading) {
      height: 100%;
    }

    :global(.ant-spin-container) {
      height: 100%;

      > div {
        height: 100% !important;
        width: 100% !important;
      }
    }

    :global(.ant-list-item) {
      padding-left: 24px;
      padding-right: 24px;
      border-bottom: 1px solid #d9d9d9 !important;
      border-top: none !important;

      @include mq("xs") {
        padding-left: 12px !important;
        padding-right: 12px !important;
      }
    }
  }

  &.leadManagement {
    width: 100% !important;
    height: 100%;

    &:global(.ant-list) {
      position: relative;
    }
  }
}

@import "Styles/mixins.scss";

.skCubeGrid {
  width: 40px;
  height: 40px;

  .skcube {
    width: 33%;
    height: 33%;
    background-color: #1890ff;
    float: left;
    @include prefix(
      "animation",
      skCubeGridScaleDelay 1.3s infinite ease-in-out
    );
  }

  .skcube1 {
    @include prefix("animation-delay", 0.2s);
  }

  .skcube2 {
    @include prefix("animation-delay", 0.3s);
  }

  .skcube3 {
    @include prefix("animation-delay", 0.4s);
  }

  .skcube4 {
    @include prefix("animation-delay", 0.1s);
  }

  .skcube5 {
    @include prefix("animation-delay", 0.2s);
  }

  .skcube6 {
    @include prefix("animation-delay", 0.3s);
  }

  .skcube7 {
    @include prefix("animation-delay", 0s);
  }

  .skcube8 {
    @include prefix("animation-delay", 0.1s);
  }

  .skcube9 {
    @include prefix("animation-delay", 0.2s);
  }
}

@include keyframes(skCubeGridScaleDelay) {
  0%,
  70%,
  100% {
    @include prefix("transform", scale3D(1, 1, 1));
  }
  35% {
    @include prefix("transform", scale3D(0, 0, 1));
  }
}

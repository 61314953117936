@import "Styles/mixins.scss";

.label {
  position: relative;
  @include inline-flexbox();
  @include align-items(center);
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  height: auto;
}

.withIcon {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(flex-end);
  position: relative;

  button {
    :global(.ant-btn) {
      padding: 0;
      min-height: 24px;
      height: 24px;
      min-width: 24px;
      width: 24px;
    }
  }
}

.editableRow {
  cursor: pointer;
  @include prefix(transition, color 0.3s);
  &:hover {
    color: #108ee9;
  }
}

.editControls {
  @include flexbox();
  @include justify-content(flex-end);

  button:first-child {
    margin-right: 8px;
  }
}

.form {
  @include flexbox();
  @include flex-direction(column);
  @include flex-wrap(nowrap !important);
  height: 100%;
  width: 100%;
}

.image-button {
  padding: 0 !important;
  background: rgba(255, 255, 255, 0.35);
  width: 32px;
  height: 32px;
  min-width: 32px !important;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  &:hover,
  &:active,
  &:focus {
    background: rgba(255, 255, 255, 0.5);
  }

  &.star {
    position: relative;
    top: 10px;
    left: 10px;
  }

  &.more {
    position: relative;
    top: 10px;
    right: 10px;
    float: right;
  }
}

.mobileForms {
  .ant-picker-dropdown {
    left: 0 !important;
    width: 100vw !important;
    height: 100% !important;
    position: fixed;
    top: 0 !important;
    right: 0;
    bottom: 0;
    z-index: 9999;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    filter: alpha(opacity=50);
    overflow: auto;
    padding: 24px;
    margin: 0 auto;
    border-radius: 0px;

    display: flex;
    justify-content: center;
    align-items: safe center;
    box-sizing: border-box;

    &.ant-picker-dropdown-hidden {
      display: none !important;
    }

    &.slide-up-enter.slide-up-enter-active,
    &.slide-up-appear.slide-up-appear-active {
      -webkit-animation-name: antFadeIn;
      animation-name: antFadeIn;
    }
    &.slide-up-enter.slide-up-enter-active,
    &.slide-up-appear.slide-up-appear-active {
      -webkit-animation-name: antFadeIn;
      animation-name: antFadeIn;
    }
    &.slide-up-leave.slide-up-leave-active {
      -webkit-animation-name: antFadeOut;
      animation-name: antFadeOut;
    }
    &.slide-up-leave.slide-up-leave-active {
      -webkit-animation-name: antFadeOut;
      animation-name: antFadeOut;
    }

    > .ant-picker-panel-container {
      background: #ffffff;
      min-width: 300px;
      max-width: calc(100% - 48px);
      border-radius: 4px;
      margin: auto 0;

      > .ant-picker-panel {
        height: 100%;
        width: 100%;

        > .ant-picker-decade-panel,
        .ant-picker-year-panel,
        .ant-picker-quarter-panel,
        .ant-picker-month-panel,
        .ant-picker-week-panel,
        .ant-picker-date-panel,
        .ant-picker-time-panel {
          height: 100%;
          width: 100%;

          > .ant-picker-body {
            height: 100%;
            width: 100%;

            > .ant-picker-content {
              height: 100%;
              width: 100%;

              .ant-picker-cell {
                &::before {
                  height: 96%;
                }

                .ant-picker-cell-inner {
                  @include flexbox();
                  @include justify-content(center);
                  @include align-items(center);
                  height: 100%;
                  width: 100%;
                  min-height: 48px;
                }
              }
            }
          }
        }

        > .ant-picker-datetime-panel {
          flex-direction: column;

          > .ant-picker-date-panel {
            border-bottom: 1px solid #f0f0f0;
          }
        }
      }
    }
  }

  .isMobile.ant-select-dropdown {
    left: 0 !important;
    width: 100vw !important;
    height: 100% !important;
    position: fixed;
    top: 0 !important;
    right: 0;
    bottom: 0;
    z-index: 9999;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    filter: alpha(opacity=50);
    overflow: auto;
    padding: 24px !important;
    margin: 0 auto;
    border-radius: 0px;

    display: flex;
    justify-content: center;
    align-items: safe center;

    &.ant-select-dropdown-hidden {
      display: none !important;
    }

    > div {
      background: #ffffff;
      overflow: auto;
      min-width: 300px;
      max-width: calc(100% - 48px);
      margin: auto;
      border-radius: 4px;
      overflow: hidden;
    }

    &.slide-up-enter.slide-up-enter-active,
    &.slide-up-appear.slide-up-appear-active {
      -webkit-animation-name: antFadeIn;
      animation-name: antFadeIn;
    }
    &.slide-up-enter.slide-up-enter-active,
    &.slide-up-appear.slide-up-appear-active {
      -webkit-animation-name: antFadeIn;
      animation-name: antFadeIn;
    }
    &.slide-up-leave.slide-up-leave-active {
      -webkit-animation-name: antFadeOut;
      animation-name: antFadeOut;
    }
    &.slide-up-leave.slide-up-leave-active {
      -webkit-animation-name: antFadeOut;
      animation-name: antFadeOut;
    }
  }

  .form {
    height: calc(100% - 53px);
  }

  .form-body {
    position: fixed;
    left: 0;
    right: 0;
    height: calc(100% - 55px - 53px);
  }

  .sticky-footer {
    position: fixed;
    left: 0;
    right: 0;
    top: calc(100% - 53px);
  }

  .phonebook-view {
    bottom: 0;
    top: unset;
    height: calc(100% - 55px - 53px);
  }

  .create-lead-project {
    margin-top: 39px;
    height: calc(100% - 55px - 53px - 39px);
  }
}

.list-item-ellipsis {
  .ant-list-item-meta-description {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.form-drawer-container {
  .ant-drawer-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.headerWithButton {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  margin-bottom: 12px;
}

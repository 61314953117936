@import "Styles/mixins.scss";

.partners {
  height: 100%;
  width: 100%;
  :global(.ant-carousel) {
    height: 100%;
    width: 100%;

    div {
      height: 100%;
      width: 100%;
    }

    .slide {
      height: 100%;
      width: 100%;
      @include inline-flexbox();
      @include justify-content(center);
      @include align-items(center);
      padding: 24px;

      > img {
        display: block;
        margin: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}

.container {
  height: calc(100% - 31px);

  h1,
  h2,
  h3,
  h4,
  h5,
  div,
  span {
    &:global(.ant-typography) {
      display: block;
      text-transform: uppercase;
      text-align: center;
      margin: 0 !important;

      &.offWhite {
        font-size: 20px;
        color: rgba(255, 255, 255, 0.7) !important;
      }
    }
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.actionContainer {
  background: rgba(250, 250, 250, 0.5);
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  padding: 6px;

  button {
    border: none !important;
    box-shadow: none !important;
    background: transparent;

    &:active,
    &:hover {
      border: none !important;
      background: transparent;
    }
  }
}

.moreOptions {
  background: transparent;

  :global(.ant-drawer-content-wrapper) {
    background: transparent;
    box-shadow: none !important;

    :global(.ant-drawer-content) {
      background: transparent;

      .action {
        text-align: center;
        font-size: 14px;
        padding: 12px 0;
        border-bottom: 1px solid rgba(250, 250, 250, 0.1);
        background: $dark;
        color: #ffffff;
        cursor: pointer;

        &.delete {
          color: $lost !important;
        }

        &:first-child {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-top: none !important;
        }

        &:nth-last-child(2) {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          border-bottom: none !important;
        }
      }

      .cancel {
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        padding: 12px 0;
        border-radius: 10px;
        margin-top: 8px;
        margin-bottom: 8px;
        background: $dark;
        color: #ffffff;
      }
    }
  }
}

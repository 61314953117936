@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.statsContainer {
  display: block;
  opacity: 1;
  @include animation(openCreate 0.3s forwards);

  &.hide {
    opacity: 0;
    @include animation(closeCreate 0.3s forwards);
  }
}

.listContainer {
  opacity: 1;
  height: 100%;

  @include animation(openCreate 0.3s forwards);

  &.hide {
    visibility: hidden;
    opacity: 0;
    @include animation(closeCreate 0.3s forwards);
  }
}

@include keyframes(openCreate) {
  0% {
    display: none;
    opacity: 0;
    height: 0px;
  }
  99% {
    opacity: 0.99;
    height: 100%;
  }
  100% {
    opacity: 1;
    display: block;
    height: 100%;
  }
}

@include keyframes(closeCreate) {
  0% {
    opacity: 1;
    display: block;
    height: 100%;
  }
  99% {
    opacity: 0.01;
    height: 1%;
  }
  100% {
    opacity: 0;
    display: none;
    height: 0px;
  }
}

@import "Styles/variables.scss";

.reportTitle {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  font-weight: 500;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum, "tnum"';
  padding-left: 12px;
  padding-right: 12px;
}

.tableHeader {
  background: $darkModeComponent;

  h4 {
    color: #ffffff;
    margin: 0;
  }
}

.reportContainer {
  max-width: 100%;
  overflow: auto;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.tableFooter {
  background: $primary;

  h4 {
    color: #ffffff;
    margin: 0;
  }
}

.percentTag {
  border-radius: 12px !important;
  // width: 75px;
  text-align: right;
  display: inline-flex !important;
  justify-self: flex-end;
  align-items: center;
  margin-right: 0 !important;
  padding-right: 4px !important;

  svg {
    height: 16px !important;
    width: 16px !important;
    font-size: 16px !important;
    vertical-align: top;
    margin-left: 4px;
  }
}

.tableBody {
  :global(.ant-skeleton-content) {
    :global(.ant-skeleton-title) {
      margin: 0;
      margin-top: 4px;
    }
    :global(.ant-skeleton-paragraph) {
      margin: 0 !important;
    }
  }

  :global(.ant-list-item-meta-description) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

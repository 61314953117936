@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.scope {
  &:global(.ant-card) {
    margin-bottom: 16px;
    @include zDepth1;

    &:last-child {
      margin-bottom: 24px;
    }

    &:global(.hover) {
      box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
        0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
      background: #efefef;
    }

    :global(.ant-card-actions) {
      background: transparent;
    }

    :global(.ant-card-cover) {
      position: relative;
      max-height: 200px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      :global(.ant-image) {
        width: 100%;
        :global(.ant-image-img) {
          height: 100%;
          width: 100%;
          max-height: 200px;
          object-fit: cover;
        }
      }
    }
  }
}

.bottomBtn {
  &:global(.ant-btn) {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    padding: 6.4px 15px;
    width: 100%;

    &.installStatus {
      font-size: 30px;
      font-weight: 400;
    }
  }
}

.radioGroup {
  margin-top: 8px;
  > :global(.ant-radio-button-wrapper) {
    width: 25%;
    text-align: center;
  }
}

.moreVert {
  &:global(.ant-btn) {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 1;
    width: 40px;
  }
}

.mobilePoCustomerName {
  position: absolute;
  bottom: -12px;
  left: 0px;
  background: $primary;
  z-index: 1;
  padding: 4px 12px;
  padding-left: 24px;
  width: auto !important;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  > h5 {
    &:global(.ant-typography) {
      color: rgba(255, 255, 255, 0.85);
    }
  }
}

.detailLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dateStamp {
  position: absolute;
  top: 4px;
  left: 4px;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.85);
  z-index: 1;
  padding: 4px 8px;
  width: auto !important;
  border-radius: 6px;
  font-size: 10px;
}

.materialDateStamp {
  position: absolute;
  top: 4px;
  right: 4px;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.85);
  z-index: 1;
  padding: 4px 8px;
  width: auto !important;
  border-radius: 6px;
  font-size: 10px;
}

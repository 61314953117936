@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.copyButton {
  &:global(.ant-btn) {
    @include inline-flexbox();
    @include justify-content(center);
    @include align-items(center);
    min-height: 40px !important;
    position: absolute;
  }
}

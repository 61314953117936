@import "Styles/variables.scss";

.time {
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 1;
  background: $darkModeComponent;

  h1,
  h2,
  h3,
  h4,
  h5,
  div,
  span {
    &:global(.ant-typography) {
      &.timeText {
        font-size: 20px;
        color: rgb(255, 255, 255) !important;
      }
    }
  }
}

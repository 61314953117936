@import "Styles/mixins.scss";

.sliderWrapper {
  @include flexbox();
  @include align-items(center);
  margin-bottom: 16px;

  :global(.ant-form-item) {
    width: 100%;
    margin-bottom: 0;
  }
}

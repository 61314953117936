@import "Styles/mixins.scss";

.album {
  :global(.ant-modal-content) {
    height: 100%;
  }
}

.hoverContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: rgba(255, 255, 255, 0);
  @include prefix(transition, background 0.3s, opacity 0.3s);

  @include flexbox();
  @include justify-content(center);
  @include align-items(center);

  &:hover {
    background: rgba(255, 255, 255, 0.7);
    opacity: 1;
  }
}

.container {
  @include zDepth1;
  overflow: hidden;
  max-height: 250px;
  margin-bottom: 16px;

  :global(.ant-card) {
    overflow: hidden;

    &:hover {
      .hoverContainer {
        background: rgba(255, 255, 255, 0.7);
        opacity: 1;
      }
    }
  }

  :global(.ant-card-cover) {
    overflow: hidden;
    height: 200px;

    :global(.ant-image) {
      height: 100%;

      :global(.ant-image-img) {
        height: 100%;
        max-height: 200px;
        object-fit: cover;
      }
    }
  }

  :global(.ant-card-actions) {
    > li {
      margin: 0;

      > span {
        width: 100%;

        > div {
          width: 100%;
          padding: 12px 0;
        }
      }
    }
  }
}

.addImageContainer {
  overflow: hidden;
  max-height: 250px;
  border: 1px dashed #bfbfbf;
  margin-bottom: 16px;

  &:hover {
    .addImage {
      color: #40a9ff;
      fill: #40a9ff;
    }
  }

  :global(.ant-card-cover) {
    overflow: hidden;
    height: 200px;

    :global(.ant-image) {
      height: 100%;

      :global(.ant-image-img) {
        height: 100%;
        max-height: 200px;
        object-fit: cover;
      }
    }
  }

  .addImage {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include flex-direction(column);
    height: 100%;
    font-size: 18px;

    @include prefix(transition, color 0.3s, fill 0.3s);
  }
}

.upload {
  width: 100%;

  > div {
    width: 100%;
  }
}

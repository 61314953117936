@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.list {
  :global(.ant-list-item-meta),
  :global(.ant-list-item-meta-content) {
    min-width: 0;
  }

  .avatar {
    padding: 8px;
    border-radius: 50%;
    color: rgba(46, 49, 52, 0.6);
    background: whitesmoke;
  }

  &:global(.ant-list-bordered) {
    border: none;
    border-top: 1px solid #f0f0f0;

    &:first-child {
      border-top: none;
    }
  }
}

.transactionList {
  &:global(.ant-list-item) {
    cursor: pointer;
    padding: 8px 24px;
    @include prefix(transition, background 0.3s);
  }

  &:hover {
    background: $rowHoverSelect;
  }

  &.none {
    background: #ffffff !important;
    height: 64px;
    margin-top: 1px;
    cursor: default;

    &:hover {
      background: #ffffff;
    }

    :global(.ant-list-item-meta) {
      @include flexbox();
      @include align-items(center);

      :global(.ant-list-item-meta-title) {
        font-weight: 400;
      }
    }
  }

  :global(.ant-list-item-meta-content) {
    :global(.ant-list-item-meta-title) {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

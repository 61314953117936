@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.card {
  &:global(.ant-card) {
    overflow: hidden;
    height: 100%;
    @include zDepth1;
    background: $darkModeComponent !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    div,
    span {
      &:global(.ant-typography) {
        display: block;
        text-transform: uppercase;
        text-align: center;
        margin: 0 !important;

        &.offWhite {
          color: rgba(0, 0, 0, 0.85) !important;
          color: rgba(255, 255, 255, 0.7) !important;
        }
      }
    }
  }
}

.reviewContainer {
  &:global(.ant-list) {
    height: 100%;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);

    :global(.ant-list-item-meta) {
      @include align-items(center);
      @include justify-content(center);

      :global(.ant-list-item-meta-content) {
        width: 180px;
        flex: unset;

        :global(.ant-list-item-meta-title) {
          font-size: 20px;
          text-transform: uppercase;
          color: #ffffff;
        }

        :global(.ant-list-item-meta-description) {
          .rating {
            color: rgba(255, 255, 255, 0.7);

            @include flexbox();
            @include justify-content(flex-start);
            @include align-items(center);
            font-size: 24px;

            :global(.ant-rate) {
              :global(.ant-rate-star-first) {
                @include flexbox();
                @include align-items(center);
              }

              :global(.ant-rate-star-second) {
                @include flexbox();
                @include align-items(center);
              }
            }
          }
        }
      }
    }
  }
}

.offWhite {
  color: rgba(0, 0, 0, 0.85) !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

@import "Styles/mixins.scss";

.mb24 {
  margin-bottom: 24px;
}

.button {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);

  svg {
    margin-right: 8px;
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.button {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
}

.balance {
  text-align: right;
  margin-top: 12px;
  padding-right: 8px;
  display: block;
}

.activity {
  margin-right: 2px;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0px;
  vertical-align: top;
  :global(.ant-card-cover) {
    min-height: 240px;
    max-height: 240px;
    width: 385px;
    overflow: hidden;
    background: $dark;

    img,
    object {
      object-fit: cover;
    }
  }
}

.clickOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.pdf {
  height: calc(100% - 2px);
  width: 100%;
  background: #ffffff;
  border-radius: 4px;

  > div {
    height: 100%;
    width: 100%;
    background: $dark;
    padding: 8px;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include flex-direction(column);
    > img {
      max-width: 45px;
      display: block;
    }

    > div {
      width: 100%;
      text-align: center;
      color: #ffffff;
      font-size: 12px;
      margin-top: 8px;
    }
  }
}

.video {
  height: 100%;
  width: 100%;
  padding: 8px;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(column);

  > img {
    max-width: 45px;
    display: block;
  }

  > div {
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    margin-top: 8px;
  }
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@import "Styles/mixins.scss";

.deleteButton {
  &:global(.ant-btn) {
    & + span {
      @include prefix(transition, color 0.3s);
    }
    &:hover {
      & + span {
        color: #ff7875;
      }
    }
  }
}

.list {
  &:global(.ant-list) {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }
}

.listItem {
  &:global(.ant-list-item) {
    padding-bottom: 0px;
    padding-top: 0px;
    padding-left: 24px;
    padding-right: 24px;

    :global(.ant-list-item-meta-title) {
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

h3 {
  &:global(.ant-typography) {
    &.shareHeader {
      &:global(.ant-typography) {
        margin: 12px !important;
      }
    }
  }
}

@import "Styles/mixins.scss";

.uploadRow {
  padding: 12px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .preview {
    width: 100%;
    height: 100%;
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
  }

  :global(.ant-list-item-meta-avatar) {
    width: 48px;
    height: 48px;
    margin-right: 12px;
    overflow: hidden;
    border-radius: 4px;
  }

  :global(.ant-list-item-meta-content) {
    width: calc(100% - 52px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    :global(.ant-list-item-meta-title) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    :global(.ant-list-item-meta-description) {
      @include flexbox();
      @include align-items(center);

      :global(.ant-progress) {
        margin-left: 12px;

        :global(.ant-progress-outer) {
          margin-right: calc(-2em - 12px);
          padding-right: calc(2em + 12px);
        }
      }
    }
  }
}

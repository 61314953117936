@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.warningText {
  color: #faad14;
}

.errorText {
  color: #f5222d;
}

.successText {
  color: #52c41a;
}

.justify {
  @include flexbox();
  @include justify-content(center);
}

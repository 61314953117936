@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.activity {
  margin-bottom: 8px;
  :global(.ant-card-cover) {
    min-height: 240px;
    max-height: 240px;
    overflow: hidden;
    background: $dark;

    img,
    video,
    object {
      object-fit: cover;
    }
  }
}

.clickOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;

  &.selecting {
    background: rgba(255, 255, 255, 0.3);
  }
}

.videoContainer {
  min-height: 240px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 100%;
    object-fit: cover;
  }
}

.pdf {
  height: 100%;
  width: 100%;
  min-height: 240px;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(column);

  > img {
    max-width: 45px;
    display: block;
  }

  > button {
    display: block;
  }

  > div {
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    margin-top: 8px;
  }
}

.video {
  height: 100%;
  width: 100%;
  min-height: 240px;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(column);
  padding: 8px;

  > img {
    max-width: 45px;
    display: block;
  }

  > div {
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    margin-top: 8px;
  }
}

.image {
  min-height: 240px;
  height: 100%;
  width: 100%;
}

.deleteText {
  color: $lost;
}

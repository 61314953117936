.radioGroup {
  width: 100%;
  border-radius: 4px;
  :global(.ant-radio-group) {
    :global(.ant-radio-button-wrapper) {
      width: 50%;
      text-align: center;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.footer {
  background: #ffffff;
  @include zDepth0;
}

.mt8 {
  margin-top: 8px;
}

.dateStamp {
  position: absolute;
  top: 4px;
  left: 4px;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.85);
  z-index: 1;
  padding: 4px 8px;
  width: auto !important;
  border-radius: 6px;
  font-size: 10px;
}

.scopeCard {
  background: #ffffff;
  @include prefix(transition, background 0.3s);
  margin-bottom: 16px !important;
  overflow: hidden;

  &.urgent {
    background: $urgent;
  }

  &.selected {
    background: $selectedRow;

    :global(.ant-card-head) {
      border-bottom: 1px solid #dcdcdc;
    }
  }

  :global(.ant-card-head) {
    @include prefix(transition, border-bottom 0.3s);
  }

  :global(.ant-card-cover) {
    img {
      width: 100%;
      max-height: 150px;
      min-height: 150px;
      object-fit: cover;
    }
  }

  :global(.ant-card-meta-title) {
    margin-bottom: 4px !important;
  }

  :global(.ant-card-meta-detail) {
    width: 100%;
  }

  * {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }

  :global(.ant-card-actions) {
    overflow: hidden;
    li {
      margin: 0;
      overflow: hidden;
      button {
        height: 42px;
        overflow: hidden;
        width: 100%;
      }
    }
  }
}

.centerIcon {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
}

.dateStamp {
  position: absolute;
  top: 8px;
  left: 4px;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.85);
  z-index: 1;
  padding: 4px 8px;
  width: auto;
  border-radius: 6px;
  font-size: 10px;
}

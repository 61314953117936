@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.drawer {
  @include flexbox();
  @include flex-direction(column);
  height: 100%;

  &:global(.lost) {
    .header {
      background: $lost;
    }

    .tabs {
      :global(.ant-tabs-nav) {
        background: $lost;
      }
    }
  }

  &:global(.hold) {
    .header {
      background: $hold;
    }

    .tabs {
      :global(.ant-tabs-nav) {
        background: $hold;
      }
    }
  }

  &:global(.closed-out) {
    .header {
      background: $closedOut;
    }

    .tabs {
      :global(.ant-tabs-nav) {
        background: $closedOut;
      }
    }
  }
}

.tabs {
  height: calc(100% - 64px);
  :global(.ant-tabs-nav) {
    margin: 0 !important;
    background: $primary;
  }

  :global(.ant-tabs-nav-list) {
    width: 100%;
    @include flexbox();
    justify-content: space-evenly;
  }

  :global(.ant-tabs-nav) {
    &::before {
      border-bottom: none !important;
    }

    :global(.ant-tabs-nav-operations) {
      display: none !important;
    }
  }

  :global(.ant-tabs-tab) {
    margin: 0 !important;
    flex-grow: 1;
    justify-content: center;
    padding-top: 0;

    > div {
      color: #e5e5e5;
      @include prefix(transition, color 0.3s !important);
    }

    &:hover > div,
    &:active > div,
    &:focus > div,
    &:global(.ant-tabs-tab-active > div) {
      color: #ffffff !important;
    }
  }

  :global(.ant-tabs-ink-bar) {
    background: #ffffff;
    height: 4px !important;
  }

  :global(.ant-tabs-content-holder) {
    flex-grow: 1;
    height: calc(100% - 34px);
  }

  :global(.ant-tabs-content) {
    height: 100%;
  }

  :global(.ant-tabs-tabpane) {
    display: flex;
    flex-direction: column;
  }
}

:global(.ant-tabs-nav-operations) {
  display: none !important;
}

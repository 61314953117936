@import "Styles/mixins.scss";

.checkbox {
  > :global(.ant-col) {
    height: 100%;
    > :global(.ant-form-item-control-input) {
      height: 100%;
    }
  }

  :global(.ant-checkbox-wrapper) {
    width: 100%;
    &:hover {
      border-radius: 50%;
    }

    > span {
      @include align-self(center);
    }
  }

  :global(.ant-checkbox) {
    border-radius: 50%;

    &::after {
      border-radius: 50%;
    }

    &:hover::after {
      border-radius: 50%;
    }
  }

  input {
    height: 24px !important;
    width: 24px !important;
    border-radius: 50%;
    outline: none;
  }

  :global(.ant-checkbox-inner) {
    height: 24px;
    width: 24px;
    border-radius: 50%;

    &::after {
      left: 30%;
    }
  }
}

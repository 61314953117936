@import "Styles/mixins.scss";

.statusTag {
  font-size: 9px;
  vertical-align: top;
  margin-top: 3px;
  line-height: unset;
}

.detail {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.resizeHandle {
  position: absolute;
  background: none rgb(255, 255, 255);
  border: 0px;
  margin: 10px;
  padding: 0px;
  cursor: grab;
  user-select: none;
  border-radius: 2px;
  height: 40px;
  width: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  overflow: hidden;
  bottom: 0px;
  right: 0px;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);

  &:focus,
  &:active {
    cursor: grabbing !important;
  }
}

.steps {
  pointer-events: none;
  position: absolute;
  left: 0px;
  top: 24px;
  z-index: 9999;

  :global(.ant-steps-item-title) {
    color: #ffffff !important;
  }

  &.modal {
    top: 79px;
  }
}

.total {
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  z-index: 9999;

  top: 65px;
  font-weight: bolder;
  font-size: 60px;
  color: #ffffff;

  &.modal {
    top: 120px;
  }
}

.card {
  &:global(.ant-card) {
    position: absolute;
    left: 0;
    bottom: 0px;
    right: 0;
    height: auto;
    max-height: 223px;
    background: #ffffff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    :global(.ant-card-head) {
      padding-left: 12px;
    }

    :global(.ant-card-body) {
      padding: 0;
      height: 100%;
      max-height: calc(100% - 56px);
      overflow: auto;
      .list {
        :global(.ant-list-item) {
          padding-left: 12px;
          padding-right: 12px;

          &:last-child {
            border-bottom: 1px solid #f0f0f0;
          }
        }
      }
    }

    &.modal {
      bottom: 53px;
    }
  }
}

.jobsiteTargetMap {
  border-radius: 0;
  outline: none;
  border: none;

  div {
    outline: none;
    border: none;
  }

  img {
    outline: none;
    border: none;
  }
}

.headerSpacer {
  height: 90px;
  background: $darkModeComponent;
  position: absolute;
  top: 0px;
  width: 100%;

  &.modal {
    top: 55px;
  }
}

.page2 {
  &:global(.content-inner) {
    padding: 24px;
    position: absolute;
    top: 90px;
    height: calc(100% - 90px);

    &.modal {
      top: 145px;
      height: calc(100% - 198px);
    }
  }
}

.postcardRadio {
  margin-bottom: 8px !important;
  @include align-items(center !important);
}

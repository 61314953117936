@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.legacyFiles {
  margin-bottom: 8px !important;
  @include zDepth1;

  :global(.ant-card-cover) {
    padding: 5px !important;

    > div {
      margin: 0px !important;
      width: 100%;
    }
  }
}

.radioGroup3 {
  width: 100%;
  border-radius: 4px;
  :global(.ant-form-item-control) {
    height: 32px;
  }

  :global(.ant-radio-group) {
    :global(.ant-radio-button-wrapper) {
      width: 33.33%;
      text-align: center;

      &:first-child {
        border-radius: 0px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-radius: 0px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

.radioGroup6 {
  width: 100%;
  :global(.ant-radio-group) {
    :global(.ant-radio-button-wrapper) {
      width: 33.33%;
      text-align: center;

      &:first-child {
        border-radius: 0px;
        border-top-left-radius: 4px;
        border-bottom-width: 0px;
      }

      &:nth-child(2) {
        border-bottom-width: 0px;
      }

      &:nth-child(3) {
        border-radius: 0px;
        border-top-right-radius: 4px;
        border-bottom-width: 0px;
      }

      &:nth-child(4) {
        border-radius: 0px;
        border-bottom-left-radius: 4px;
        border-left: 1px solid #d9d9d9;
        overflow: hidden;
        border-top-width: 0.5px;
      }

      &:nth-child(5) {
        overflow: hidden;
        border-top-width: 0.5px;
      }

      &:last-child {
        border-radius: 0px;
        border-bottom-right-radius: 4px;
        overflow: hidden;
        border-top-width: 0.5px;
      }
    }
  }
}

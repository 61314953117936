.errorContainer {
  text-align: center;
  height: 100%;
  width: 100%;
  background: #f0f2f5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.loginContainer {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  letter-spacing: -0.04em;
  margin: 0;
  a {
    padding-bottom: 1;
    color: #ffffff;
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    transition: border-color 0.1s ease-in;
  }
}

.container404 {
  margin-top: 20vh;
  h1 {
    font-size: 130;
    font-weight: 300;
    color: #ffffff;
    margin: 0;
  }
  h4 {
    color: #ffffff;
    font-size: 18;
    font-weight: normal;
    margin-top: -24px;
    margin-bottom: 34;
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.filterDrawer {
  :global(.ant-drawer-content-wrapper) {
    width: 400px;
  }

  :global(.ant-drawer-content-wrapper) {
    @include mq("sm") {
      width: 100%;
    }
  }

  :global(.ant-drawer-header) {
    width: 100%;

    :global(.ant-drawer-header-title) {
      width: 100%;

      :global(.ant-drawer-title) {
        width: 100%;
      }
    }
  }
}

.filterHeader {
  @include flexbox();
  height: 64px;
  @include align-items(center);
  background: $primary;

  :global(button) {
    margin: 0 12px;
    border: none;
    outline: none;
    box-shadow: none;
  }
}

.filterTitle {
  font-size: 1rem;
  text-align: center;
  @include flex-grow(1);
  margin-bottom: 0;
  color: #ffffff;
}

.filterControls {
  @include flexbox();
  @include justify-content(space-between);
}

.filterSubMenu {
  border-bottom: 1px solid #d9d9d9;
  :global(.ant-menu-item) {
    height: unset !important;
    padding-right: 16px !important;
    padding-left: 24px !important;
    overflow: visible;
  }

  :global(.ant-menu-submenu-title) {
    &:hover {
      color: rgba(0, 0, 0, 0.65);
      > i::before {
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.65),
          rgba(0, 0, 0, 0.65)
        ) !important;
      }

      > i::after {
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.65),
          rgba(0, 0, 0, 0.65)
        ) !important;
      }
    }
  }
}

@import "Styles/mixins.scss";

.deleteButton {
  cursor: pointer;
  width: 100%;
  @include flexbox();
  @include justify-content(flex-end);
  @include align-items(center);
}

.label {
  width: 100%;
  padding-bottom: 4px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  vertical-align: middle;
  position: relative;
  max-width: 100%;
  min-height: 1px;

  > label {
    width: 100%;
    margin: 0;
    height: auto;
    position: relative;
    @include inline-flexbox();
    @include align-items(center);
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 600;

    &:before {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }

    > div {
      width: 100%;
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);

      > button {
        width: 32px;
      }
    }
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.balanceCard {
  &:global(.ant-card) {
    background: $green;
    @include zDepth1;

    .title {
      display: block;
      text-align: center;
      margin-bottom: 0;
      color: #ffffff;
    }

    .subTitle {
      display: block;
      text-align: center;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.65);
    }
  }
}

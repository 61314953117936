@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.dashboardContainer {
  padding-top: 24px;
  padding-left: 24px;
  padding-bottom: 24px;
  padding-right: 8px;

  :global(#globalSearchInput > div.ant-select-selection),
  :global(#globalSearchInput > div.ant-select-selection:hover),
  :global(#globalSearchInput.ant-select-focusedv > div.ant-select-selection)
    :global(#globalSearchInput.ant-select-focused > div.ant-select-selection) {
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
  }

  @media (max-width: 767px) {
    padding: 16px !important;
  }
}

.leadTimesContainer {
  background: #f0f0f0;
  :global(.ant-card-head) {
    background: #ffffff;
  }
  :global(.ant-card-body) {
    height: 200px;

    :global(.ant-list-split .ant-list-item) {
      background: #ffffff;

      &:last-child {
        border-bottom: 1px solid #f0f0f0;
      }
    }

    h4 {
      margin: 0;
    }

    @include mq("xs") {
      height: unset;
    }
  }
}

.goals {
  :global(.ant-card-body) {
    height: 202px;

    @include mq("xs") {
      height: unset;
    }
  }
}

.mb16 {
  margin-bottom: 16px;
}

.mb16at575 {
  @include mq("sm") {
    margin-bottom: 16px;
  }
}

.activity {
  margin-bottom: 8px !important;
  @include zDepth1;

  .body {
    padding: 4px 12px;

    .content > span {
      white-space: pre-wrap;
    }
  }
}

.affix {
  margin-top: 24px;
  margin-bottom: 8px;
  width: 100%;
  @include flexbox();
  @include justify-content(Center);
  @include align-items(center);

  &:first-child {
    margin-top: 0px;
  }

  > div {
    width: 100%;
  }
}

.dateStamp {
  margin-bottom: 8px;
  width: 50%;
  min-width: 180px;

  @include flexbox();
  @include justify-content(Center);
  @include align-items(center);
  background: $dark;
  color: rgba(255, 255, 255, 0.85);
  border-radius: 20px;
  padding: 2px 8px;
  font-size: 11px;
  @include zDepth1;
  margin: auto;
}

.hashtagSearch {
  &:global(.ant-select) {
    margin-bottom: 8px;
    > div {
      border-radius: 20px !important;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    }
  }
}

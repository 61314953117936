@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.dateStamp {
  margin-bottom: 16px;
  width: 50%;
  min-width: 180px;
  @include flexbox();
  @include justify-content(Center);
  @include align-items(center);
  background: $dark;
  color: rgba(255, 255, 255, 0.85);
  border-radius: 20px;
  padding: 2px 8px;
  font-size: 11px;
  cursor: pointer;
  opacity: 1;
  @include prefix(transition, opacity 0.3s);
  @include zDepth1;
  pointer-events: all;

  > .copy {
    @include prefix(transition, opacity 0.3s, padding-right 0.3s);
    opacity: 0;
    padding-right: 0px;
  }

  > .stamp {
    @include prefix(transition, margin-left 0.3s);
    margin-left: -17px;
  }

  &:hover {
    opacity: 0.8;

    > .stamp {
      margin-left: 0px;
    }
    > .copy {
      opacity: 1;
      padding-right: 4px;
    }
  }
}

.mapContainer {
  max-height: 100%;
  > div {
    height: 100%;
    > div {
      height: 100%;
    }
  }
}

.affix {
  margin-top: 24px;
  width: 100%;
  pointer-events: none;
  &:first-child {
    margin-top: 0px;
  }

  > div {
    width: 100%;
    > div {
      width: 100%;
      @include flexbox();
      @include justify-content(Center);
      @include align-items(center);
    }
  }
}

.projectCarousel {
  position: absolute;
  bottom: 0px;
  height: 293px;
  width: 100%;
  z-index: 1;

  > div {
    height: 100%;
    > div {
      height: 100%;
      > div {
        height: 100%;
        > div {
          height: 100%;
          > div {
            height: 100%;
          }
        }
      }
    }
  }

  :global(.slick-slide) {
    border-radius: 4px;
    padding-top: 8px;
    padding-left: 3px;
    padding-right: 3px;
    height: 293px;
    overflow: hidden;
    z-index: 1;
    position: relative;

    > div {
      position: relative;
      height: 100%;
    }

    :global(.ant-card-body) {
      padding: 12px;
      padding-bottom: 4px;
    }

    .carouselSlide {
      height: 283px;
    }
  }
}

.carouselSlide {
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  z-index: 1;
}

.length0 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
}

.mobileLoader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    height: 40px !important;
  }
}

.mobileLength0 {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  height: 48px !important;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  background: #ffffff;
  margin: 12px;
  border-radius: 4px;
}

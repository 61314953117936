@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.activity {
  margin-bottom: 8px !important;
  @include zDepth1;
  overflow: hidden;

  .dashboardHeader {
    text-align: left;
    padding: 4px 12px;
    border-bottom: 1px solid #f0f0f0;
    border-top: none;

    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);

    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .body {
    padding: 4px 12px;

    .content > span {
      white-space: pre-wrap;
    }
  }

  &._global {
    background: $dark;
    color: rgba(255, 255, 255, 0.9);

    button {
      background: $dark;
      color: rgba(255, 255, 255, 0.9);
    }
  }
}

.comment {
  padding-bottom: 12px;

  &:last-child {
    padding-bottom: 0;
  }

  :global(.ant-comment) {
    :global(.ant-comment-inner) {
      padding: 0;
    }

    :global(.ant-comment-content) {
      background: rgb(240, 242, 245);
      border-radius: 14px;
      padding: 4px 14px;

      :global(.ant-comment-content-author) {
        margin-bottom: 0;
      }

      :global(.ant-comment-content-author-name) {
        font-weight: bolder;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }

  &.replyToComment {
    :global(.ant-comment-content-author-name) {
      width: 100%;
      padding-right: 0;
    }

    .replyTo {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);
      height: 40px;
      background: rgba(16, 142, 233, 0.3);
      margin-left: -10px;
      margin-right: -10px;
      margin-top: -2px;
      padding-left: 10px;
      border-left: 6px solid $primary;
      margin-bottom: 6px;
      border-radius: 6px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.55);

      .replyToIcon {
        @include flexbox();
        @include align-items(center);
        svg {
          margin-right: 4px;
        }
      }

      .replyToImage {
        // background-repeat: no-repeat !important;
        // background-size: cover !important;
        // background-position: center !important;
        overflow: hidden;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        width: 25%;
        height: 40px;
      }
    }
  }
}

.ellipsis {
  box-shadow: -5px 0px 10px 0px rgba(255, 255, 255, 1),
    -15px 0px 10px 0px rgba(255, 255, 255, 1),
    -15px 0px 10px 0px rgba(255, 255, 255, 1);
}

.sanitizedBody {
  span {
    white-space: pre-line;
  }
}

@import "Styles/mixins.scss";

.temp {
  height: 100%;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(column);
}

h1,
h2,
h3,
h4,
h5,
div,
span {
  &:global(.ant-typography) {
    &.offWhite {
      font-size: 20px;
      color: rgba(255, 255, 255, 0.7) !important;
    }
  }
}

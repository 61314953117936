@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin zDepth1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

@mixin zDepth0 {
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.14), 0 -1px 5px 0 rgba(0, 0, 0, 0.12),
    0 -3px 1px -2px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

@mixin zDepth2 {
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

@mixin zDepth12 {
  box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14),
    0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
}

// use
// @include input-placeholder {
//     color: $grey;
// }

$breakpoints: (
  "xs": 575px,
  "sm": 767px,
  "md": 991px,
  "lg": 1199px,
  "xl": 1599px,
  "xxl": 1600px,
);

@mixin mq($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == min {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

// use
// @include mq('tablet-wide') {
//     padding-top: 4rem;
//     font-size: 2.4rem;
// }

@mixin prefix(
  $p1,
  $v1,
  $v2: null,
  $v3: null,
  $v4: null,
  $v5: null,
  $v6: null,
  $v7: null,
  $vendors: webkit moz ms o
) {
  @if $vendors {
    @if $v7 {
      @each $vendor in $vendors {
        #{"-" + $vendor + "-" + $p1}: #{$v1}, #{$v2}, #{$v3}, #{$v4}, #{$v5},
          #{$v6}, #{$v7};
      }
      #{$p1}: #{$v1}, #{$v2}, #{$v3}, #{$v4}, #{$v5}, #{$v6}, #{$v7};
    } @else if $v6 {
      @each $vendor in $vendors {
        #{"-" + $vendor + "-" + $p1}: #{$v1}, #{$v2}, #{$v3}, #{$v4}, #{$v5},
          #{$v6};
      }
      #{$p1}: #{$v1}, #{$v2}, #{$v3}, #{$v4}, #{$v5}, #{$v6};
    } @else if $v5 {
      @each $vendor in $vendors {
        #{"-" + $vendor + "-" + $p1}: #{$v1}, #{$v2}, #{$v3}, #{$v4}, #{$v5};
      }
      #{$p1}: #{$v1}, #{$v2}, #{$v3}, #{$v4}, #{$v5};
    } @else if $v4 {
      @each $vendor in $vendors {
        #{"-" + $vendor + "-" + $p1}: #{$v1}, #{$v2}, #{$v3}, #{$v4};
      }
      #{$p1}: #{$v1}, #{$v2}, #{$v3}, #{$v4};
    } @else if $v3 {
      @each $vendor in $vendors {
        #{"-" + $vendor + "-" + $p1}: #{$v1}, #{$v2}, #{$v3};
      }
      #{$p1}: #{$v1}, #{$v2}, #{$v3};
    } @else if $v2 {
      @each $vendor in $vendors {
        #{"-" + $vendor + "-" + $p1}: #{$v1}, #{$v2};
      }
      #{$p1}: #{$v1}, #{$v2};
    } @else if $v1 {
      @each $vendor in $vendors {
        #{"-" + $vendor + "-" + $p1}: #{$v1};
      }
      #{$p1}: #{$v1};
    }
  }
}

//   use
// @include prefix('box-sizing', 'border-box', moz webkit);

// Flexbox display
@mixin flexbox() {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
}

// Inline Flexbox display
@mixin inline-flexbox() {
  display: -webkit-inline-box !important;
  display: -moz-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: -webkit-inline-flex !important;
  display: inline-flex !important;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-shrink: $shrink;
  flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($width) {
  -webkit-flex-basis: $width;
  -moz-flex-basis: $width;
  -ms-flex-basis: $width;
  flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  -ms-flex-align: $align;
  align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}

// remove box-shadow
@mixin remove-box-shadow() {
  -webkit-box-shadow: unset;
  -moz-box-shadow: unset;
  -ms-box-shadow: unset;
  box-shadow: unset;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

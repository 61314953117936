@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.actionCard {
  &:global(.ant-card) {
    @include zDepth1;
    margin-bottom: 16px;
    height: 112px;
    position: relative;
    @include prefix(transition, opacity 0.3s);

    &:hover {
      opacity: 0.9;
    }

    &.mobile {
      margin-bottom: 2px;
    }

    .icon {
      position: absolute;
      top: 0px;
      left: 0px;

      svg {
        font-size: 110px !important;
      }
    }

    .meta {
      margin-left: 100px;
      height: 100%;
      overflow: hidden;

      :global(.ant-card-meta-title) {
        color: #ffffff;
      }

      :global(.ant-card-meta-description) {
        color: rgba(255, 255, 255, 0.65);
      }
    }
  }
}

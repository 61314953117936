@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.scope {
  &:global(.ant-card) {
    margin-bottom: 16px;
    @include zDepth1;

    &:last-child {
      margin-bottom: 24px;
    }

    &:global(.hover) {
      box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
        0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
      background: #efefef;
    }

    :global(.ant-card-actions) {
      background: transparent;
    }

    :global(.ant-card-cover) {
      position: relative;
      max-height: 200px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      :global(.ant-image) {
        width: 100%;
        :global(.ant-image-img) {
          height: 100%;
          width: 100%;
          max-height: 200px;
          object-fit: cover;
        }
      }
    }
  }
}

.bottomBtn {
  &:global(.ant-btn) {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    padding: 6.4px 15px;
    width: 100%;

    &.installStatus {
      font-size: 30px;
      font-weight: 400;
    }
  }
}

.radioGroup {
  margin-top: 8px;
  > :global(.ant-radio-button-wrapper) {
    width: 25%;
    text-align: center;
  }
}

.moreVert {
  &:global(.ant-btn) {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 1;
    width: 40px;
  }
}

.mobilePoCustomerName {
  position: absolute;
  bottom: -12px;
  left: 0px;
  background: $primary;
  z-index: 1;
  padding: 4px 12px;
  padding-left: 24px;
  width: auto !important;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 32px;

  @include flexbox();
  @include align-items(center);

  > h5 {
    &:global(.ant-typography) {
      color: rgba(255, 255, 255, 0.85);
    }
  }
}

.detailLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.assignedToContainer {
  // pointer-events: none;
  position: absolute;
  z-index: 100;
  top: 31px;
  left: 4px;
  width: auto !important;

  span {
    position: absolute;
    left: 0;
    @include zDepth1;
    border: 1px solid rgba(0, 0, 0, 0.15);

    &:nth-child(2) {
      left: 32px;
    }

    &:nth-child(3) {
      left: 64px;
    }

    &:nth-child(4) {
      left: 96px;
    }
  }
}

.dateStamp {
  @include zDepth1;
  position: absolute;
  top: 4px;
  left: 4px;
  background: rgb(88, 88, 88);
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  z-index: 1;
  padding: 4px 8px;
  width: 110px !important;
  border-radius: 6px;
  font-size: 10px;
  cursor: pointer;
  text-align: center;

  &::before {
    @include prefix(transition, opacity 0.3s);
    content: "";
    opacity: 0;
    text-align: center;
    height: calc(100% - 8px);
    width: 100%;
    position: absolute;
    left: 0;
    padding: 4px 8px;
    font-size: 10px;
    cursor: pointer;
    font-weight: 500;
    z-index: 2;
    padding-top: 0;
    padding-bottom: 0;
    color: rgba(255, 255, 255, 0.85);
    border-radius: 6px;
  }

  &:hover::before {
    content: "Change Due Date";
    background: rgb(88, 88, 88);
    opacity: 1;
  }

  &.due {
    background: $lost;
    &:hover::before {
      content: "Change Due Date";
      background: $lost;
      opacity: 1;
    }
  }

  &.none {
    background: #ffffff;
    color: rgba(0, 0, 0, 0.85);
    &:hover::before {
      content: "Add Due Date";
      background: #ffffff;
      color: rgba(0, 0, 0, 0.85);
      opacity: 1;
    }
  }
}

.avatar {
  &:global(.ant-avatar) {
    cursor: pointer;

    &::before {
      @include prefix(transition, opacity 0.3s);
      opacity: 0;
      // font-family: "Font Awesome 5 Free";
      // content: "\f304";
      content: "";
      background: rgba(0, 0, 0, 0.65);
      height: 42px;
      width: 42px;
      border-radius: 50%;
      position: absolute;
      left: 0;
    }

    &:hover::before {
      opacity: 1;
    }
  }
}

.imageContainer {
  opacity: 1;
  @include prefix(transition, opacity 0.3s);
  &:hover {
    opacity: 0.75;
  }
}

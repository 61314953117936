@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.builderSearch {
  margin-bottom: 16px !important;
}

.globalSearchDropdown {
  border-radius: 4px !important;
  padding: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);

  :global(.ant-input-affix-wrapper),
  :global(.ant-input) {
    background: transparent;
    border: none;
  }

  :global(.ant-input-prefix) {
    color: #bfbfbf;
    margin-right: 8px;
  }

  :global(.ant-select-item-group) {
    font-weight: bold;
    background: $menuHeader;
    color: $menuHeaderText;
    text-transform: uppercase;
  }

  :global(.ant-select-item-option) {
    border-bottom: 1px solid #ededed;
  }

  :global(.ant-select-dropdown-menu-item) {
    padding-left: 16px;
    border-bottom: 1px solid #ededed;
  }

  :global(.ant-select-dropdown-menu-item.show-all) {
    text-align: center;
    cursor: default;
  }

  :global(.ant-select-dropdown-menu) {
    max-height: 300px;
  }

  :global(.ant-select-selection__placeholder) {
    padding-left: 20px;
  }
}

.builderItem {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.container {
  padding: 24px;
}

.detailsContainer {
  padding: 12px 24px 24px 24px;
  overflow-x: hidden;
}

.actionButtons {
  height: 56px;
  margin-bottom: 12px;
  @include flexbox();
  @include justify-content(space-evenly);

  button {
    height: 100%;
    width: 100%;
    border: none !important;
    box-shadow: none !important;

    &::after,
    &:active,
    &:hover {
      border: none !important;
      @include prefix(animation, none !important);
    }
  }
}

.red {
  color: $lost;
}

.green {
  color: $green;
}

.actionContainer {
  @include flexbox();
  @include justify-content(space-between);
}

.redBtn {
  @include prefix(transition, background 0.3s, color 0.3s, border-color 0.3s);
  background-color: $actionRed;
  color: $actionRedColor;
  border-color: #d9d9d9;

  &:hover,
  &:active,
  &:focus {
    background-color: $actionRedHover;
    border-color: $actionRedHover;
    color: #ffffff;
  }
}

.grayBtn {
  @include prefix(transition, background 0.3s, color 0.3s, border-color 0.3s);
  background-color: $actionGray;
  color: $actionGrayColor;
  border-color: #bfbfbf;

  &:hover,
  &:active,
  &:focus {
    background-color: $actionGrayHover;
    border-color: $actionGrayHover;
    color: #ffffff;
  }
}

.greenBtn {
  @include prefix(transition, background 0.3s, color 0.3s, border-color 0.3s);
  background-color: $actionGreen;
  color: $actionGreenColor;
  border-color: #b5e3bd;

  &:hover,
  &:active,
  &:focus {
    background-color: $actionGreenHover;
    border-color: $actionGreenHover;
    color: #ffffff;
  }
}

.withIcon {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  position: relative;

  button {
    &:global(.ant-btn) {
      padding: 0;
      min-height: 24px;
      height: 24px;
      min-width: 24px;
      width: 24px;
    }
  }
}

h4 {
  margin-bottom: 1px;
}

.row {
  margin-bottom: 1em;
}

.imageBody {
  height: calc(100% - 64px - 34px) !important;
  position: fixed;
  padding: 0 !important;
  @include prefix(transition, height 0.3s);
  width: 100%;
  overflow: hidden;

  &.selecting {
    height: calc(100% - 64px - 34px - 53px) !important;
  }
}

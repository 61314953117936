@import "Styles/mixins.scss";
@import "Styles/variables.scss";

h4.relatedTitles {
  font-size: 16px;
  color: $dark;
}

.relatedContainer {
  &:global(.ant-card) {
    background-color: #ffffff;
    @include zDepth1;
    margin-bottom: 16px;
    border-radius: 4px;
    overflow: hidden;

    :global(.ant-card-head) {
      margin: 0;
    }

    .h4 {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
    }

    .panelDetail {
      float: right;
    }

    .list {
      :global(.ant-collapse-content-box) {
        padding: 0 !important;
      }
    }
  }
}

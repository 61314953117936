@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.mb24 {
  margin-bottom: 24px;
}

.button {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);

  svg {
    margin-right: 8px;
  }
}

.reviewSection {
  height: 188px;
  @include prefix(transition, height 0.3s);

  &.collapsed {
    height: 32px;

    .reviewSectionChild {
      .collapseTrigger {
        > svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .reviewSectionChild {
    height: 100%;
    overflow: hidden;

    .collapseTrigger {
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      z-index: 1;

      > svg {
        @include prefix(transition, transform 0.3s);
        transform: rotate(0deg);
      }
    }

    .reviewNotes {
      opacity: 1;
      @include prefix(transition, opacity 0.3s);

      &.collapsed {
        opacity: 0;
      }
    }
  }
}

.fab {
  &:global(.ant-btn) {
    height: 56px;
    width: 56px;
    z-index: 2001;
    @include inline-flexbox();
    @include justify-content(center);
    @include align-items(center);
    position: absolute;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
    cursor: pointer;
    outline: none;
    padding: 0;
    -webkit-user-drag: none;
    font-weight: bold;
    font-size: 18px;
    z-index: 2;
    right: 24px;
    bottom: 265px;
    @include prefix(transition, bottom 0.3s);

    &.mold {
      bottom: 345px;

      &.collapsed {
        bottom: 189px;
      }
    }

    &.collapsed {
      bottom: 109px;
    }
  }
}

.scopeCol {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.activity {
  &:global(.ant-card) {
    margin-right: 2px;
    overflow: hidden;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0px;
    vertical-align: top;
    :global(.ant-card-cover) {
      min-height: 240px;
      max-height: 240px;
      width: 385px;
      overflow: hidden;
      background: $dark;

      img,
      object {
        object-fit: cover;
      }
    }
  }
}

.pdf {
  height: calc(100% - 2px);
  width: 100%;
  background: #ffffff;
  border-radius: 4px;

  > div {
    height: 100%;
    width: 100%;
    background: $dark;
    padding: 8px;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include flex-direction(column);
    > img {
      max-width: 45px;
      display: block;
    }

    > div {
      width: 100%;
      text-align: center;
      color: #ffffff;
      font-size: 12px;
      margin-top: 8px;
    }
  }
}

.deleteText {
  color: $lost;
}

.video {
  height: 100%;
  width: 100%;
  padding: 8px;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(column);

  > img {
    max-width: 45px;
    display: block;
  }

  > div {
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    margin-top: 8px;
  }
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.clickOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.mediaContainer {
  white-space: nowrap;
  overflow: auto;
  width: 100%;
  border-radius: 4px;
}

.collapse {
  border-radius: 4px;
  margin-bottom: 24px;
  margin-top: 24px;

  :global(.ant-collapse-header) {
    font-weight: 700;
  }
}

.scopeDesc {
  height: 188px;
  @include prefix(transition, height 0.3s);

  &.scopeDescCollapsed {
    height: 32px;

    .reviewSectionChild {
      .collapseTrigger {
        > svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .reviewSectionChild {
    height: 100%;
    overflow: hidden;

    .collapseTrigger {
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      z-index: 1;

      > svg {
        @include prefix(transition, transform 0.3s);
        transform: rotate(0deg);
      }
    }

    .reviewNotes {
      opacity: 1;
      @include prefix(transition, opacity 0.3s);

      &.scopeDescCollapsed {
        opacity: 0;
      }
    }
  }
}

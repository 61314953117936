@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.actionContainer {
  @include flexbox();
  @include justify-content(space-between);
  gap: 6px;
}

.redBtn {
  &:global(.ant-btn) {
    @include prefix(transition, background 0.3s, color 0.3s, border-color 0.3s);
    background-color: $actionRed;
    color: $actionRedColor;
    border-color: #d9d9d9;
    padding: 4px 10px;
    margin: 0px !important;
    width: 110px;

    &:hover,
    &:active,
    &:focus {
      background-color: $actionRedHover;
      border-color: $actionRedHover;
      color: #ffffff;
    }

    &.fullWidth {
      width: 100% !important;
    }
  }
}

.grayBtn {
  &:global(.ant-btn) {
    @include prefix(transition, background 0.3s, color 0.3s, border-color 0.3s);
    background-color: $actionGray;
    color: $actionGrayColor;
    border-color: #bfbfbf;
    padding: 4px 10px;
    margin: 0px !important;
    width: 110px;

    &:hover,
    &:active,
    &:focus {
      background-color: $actionGrayHover;
      border-color: $actionGrayHover;
      color: #ffffff;
    }
  }
}

.greenBtn {
  &:global(.ant-btn) {
    @include prefix(transition, background 0.3s, color 0.3s, border-color 0.3s);
    background-color: $actionGreen;
    color: $actionGreenColor;
    border-color: #b5e3bd;
    padding: 4px 10px;
    margin: 0px !important;
    width: 110px;

    &:hover,
    &:active,
    &:focus {
      background-color: $actionGreenHover;
      border-color: $actionGreenHover;
      color: #ffffff;
    }
  }
}

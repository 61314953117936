@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.hoverContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: rgba(255, 255, 255, 0);
  @include prefix(transition, background 0.3s, opacity 0.3s);

  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
}

.document {
  margin-top: 24px;
  overflow: hidden;
  @include animation(openDocument 0.3s forwards);

  &:global(.hide-doc) {
    @include animation(closeDocument 0.3s forwards);
  }

  :global(.ant-card) {
    overflow: hidden;

    &:hover {
      .hoverContainer {
        background: rgba(255, 255, 255, 0.7);
        opacity: 1;
      }
    }

    :global(.ant-card-body) {
      padding: 24px 12px;
    }
  }

  :global(.ant-card-cover) {
    overflow: hidden;

    > img {
      margin: auto;
      margin-top: 24px;
      width: 40%;
      min-width: 110px;
    }
  }

  :global(.ant-card-body) {
    :global(.ant-card-meta-title),
    :global(.ant-card-meta-description) {
      text-align: center;
      font-size: 14px;
    }
  }

  :global(.ant-card-actions) {
    > li {
      margin: 0;

      > span {
        width: 100%;

        > div {
          width: 100%;
          padding: 12px 0;
        }
      }
    }
  }
}

@include keyframes(openDocument) {
  0% {
    transform: scale(0);
    flex: 0 0 0%;
    padding-left: 0px;
    padding-right: 0px;
  }
  25% {
    transform: scale(0.25);
    padding-left: 2px;
    padding-right: 2px;
  }
  50% {
    transform: scale(0.5);
    padding-left: 4px;
    padding-right: 4px;
  }
  75% {
    transform: scale(0.75);
    padding-left: 6px;
    padding-right: 6px;
  }
  99% {
    transform: scale(0.99);
    padding-left: 7.92px;
    padding-right: 7.92px;
  }
  100% {
    transform: scale(1);
    flex: initial;
    padding-left: 8px;
    padding-right: 8px;
  }
}

@include keyframes(closeDocument) {
  0% {
    transform: scale(1);
    flex: initial;
    padding-left: 8px;
    padding-right: 8px;
  }
  25% {
    transform: scale(0.75);
    padding-left: 6px;
    padding-right: 6px;
  }
  50% {
    transform: scale(0.5);
    padding-left: 4px;
    padding-right: 4px;
  }
  75% {
    transform: scale(0.25);
    padding-left: 2px;
    padding-right: 2px;
  }
  99% {
    transform: scale(0.01);
    padding-left: 0.08px;
    padding-right: 0.08px;
  }
  100% {
    transform: scale(0);
    flex: 0 0 0%;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.progress {
  @include flexbox();
  @include align-items(center);

  :global(.ant-progress-outer) {
    @include flex-grow(1);
    padding: 0;
    margin: 0;
    width: unset;
  }

  :global(.ant-progress-text) {
    width: unset;
  }
}

.imageRow {
  @include flexbox();
  @include justify-content(flex-start);
  @include align-items(center);
  padding-left: 46px;
  padding-top: 4px;
}

.createCommentActionButtons {
  @include flexbox();
  @include justify-content(space-evenly);
  @include align-items(center);

  > div {
    text-align: center;
  }

  :global(.ant-form-item-control-input-content),
  div {
    display: block;
    width: 100%;
  }

  button {
    box-shadow: none !important;
    border: none !important;
    padding: 10px 24px;
    height: 42px;

    &::after,
    &:active,
    &:hover {
      @include prefix(animation, none !important);
      border: none !important;
    }
  }

  :global(.ant-dropdown-menu) {
    max-height: 180px;
    overflow: auto;
  }
}

.uploadRow {
  @include flexbox();
  @include align-items(center);

  :global(.ant-spin-nested-loading) {
    width: 100%;
  }
}

.identity {
  padding: 8px 24px;
  background: $rowHoverSelect;
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.shareContentContainer {
  background: #ffffff;
  @include zDepth1;
  height: 0;
  padding: 0 24px;
  @include prefix(transition, height 0.3s, padding 0.3s);
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  &.selecting {
    height: 53px;
    padding: 10px 24px;
  }

  .shareContent {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.globalSearchDropResult {
  @include flexbox();
  :global(.gsLeft) {
    width: 32px;
    line-height: 44px;
    margin-right: 12px;
  }

  :global(.gsRight) {
    @include flex-grow(auto);

    :global(.gsTopLine) {
      font-weight: 600;
    }
  }
}

.searchContainer {
  top: -1;
  height: 66px;
  position: absolute;
  width: calc(100% + 1px);
  margin-left: -24px;
  background: transparent;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  @include prefix(transition, opacity 0.3s);

  .closeIcon {
    left: 24px;
    bottom: 16px;
    transform-origin: center;
    position: absolute !important;
    transform: scale(0);
    @include prefix(transition, transform 0.3s);

    &.showSearch {
      z-index: 5;
      transform: scale(1) rotate(180deg);
    }
  }

  .searchList {
    position: fixed;
    height: 0px;
    width: 100%;
    border-bottom: 1px solid #ededed;
    background-color: #ffffff;
    top: 64px;
    @include prefix(transition, height 0.3s);
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);

    .listHeader {
      padding: 6px 24px;
      background: $menuHeader;

      h4 {
        margin: 0;
        color: $menuHeaderText;
      }
    }

    .listItem {
      cursor: pointer;
      padding: 8px 24px;
    }
  }

  &.searchOpen {
    pointer-events: all;
    z-index: 3;
    opacity: 1;

    .searchList {
      height: calc(100% - 64px);
      overflow: auto;
    }
  }

  form {
    height: 66px;
    width: 100%;

    * {
      height: 66px;
      width: 100%;
    }

    input {
      padding: 4px 56px;
      border-radius: 0;
      background-color: transparent;
      color: #ffffff !important;
    }
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.container {
  background: #ebebeb;
  padding: 12px;
  height: 100%;
  width: 100%;
}

.hashtagSearch {
  &:global(.ant-select) {
    text-align: left !important;
    > div {
      border-radius: 20px !important;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    }
  }
}

.activity {
  margin-bottom: 8px !important;
  @include zDepth1;

  .body {
    padding: 4px 12px;

    .content > span {
      white-space: pre-wrap;
    }
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.zoomContainer {
  overflow: hidden;
}

.topRow {
  height: 25%;
  width: 100%;
  // overflow-y: hidden;
  // overflow-x: scroll;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;

  border: 1px solid;

  &.zoomRow {
    overflow: hidden;

    .topRowZoom {
      display: block;
    }

    .bottomRowZoom {
      display: none;
    }

    .regularRow {
      display: none;
    }
  }
}

.onDeck {
  padding-top: 4px;
  padding-left: 4px;
  padding-bottom: 4px;
  height: 98px;
  width: 100%;
  // overflow-y: hidden;
  // overflow-x: scroll;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;

  border: 1px solid;

  &.zoomRow {
    overflow: hidden;

    .topRowZoom {
      display: none;
    }

    .bottomRowZoom {
      display: none;
    }

    .regularRow {
      display: block;
    }

    .tag {
      height: 76px !important;
    }
  }

  .tag {
    width: 320px;
    display: inline-block;
    margin-right: 4px;
  }
}

.bottomRow {
  height: calc(75% - 98px);
  width: 100%;
  // overflow-y: hidden;
  // overflow-x: scroll;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;

  border: 1px solid;

  &.zoomRow {
    overflow: hidden;

    .topRowZoom {
      display: none;
    }

    .bottomRowZoom {
      display: block;
    }

    .regularRow {
      display: none;
    }
  }
}

.column {
  display: inline-block;
  @include zDepth1;
  width: 320px;
  height: 100%;
  vertical-align: top;
  background: rgb(240, 242, 245);
  @include prefix(transition, width 0.3s);

  &:last-child {
    margin-right: 0;
  }

  .empty {
    background: $green;
  }

  .header {
    padding-left: 12px;
    padding-right: 12px;
    height: 46px;
    background: #ffffff;
    z-index: 1;
    position: relative;
    @include zDepth1;

    :global(.ant-list-item-meta-title) {
      font-weight: 800;
      text-transform: uppercase;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .content {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: none;
    height: calc(100% - 46px);
    width: 100%;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 4px;

    &.zoomColumn {
      overflow: hidden;
    }

    > .sortable {
      height: 100%;
    }
  }
}

.zoomFab {
  box-sizing: border-box;
  position: fixed;
  white-space: nowrap;
  z-index: 2000;
  padding-left: 0;
  list-style: none;

  height: 56px;
  width: 56px;
  bottom: 24px;
  right: 24px;
  border-radius: 50%;

  button {
    height: 56px;
    width: 56px;
    z-index: 2001;
    @include inline-flexbox();
    @include justify-content(center);
    @include align-items(center);
    position: relative;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
    cursor: pointer;
    outline: none;
    padding: 0;
    -webkit-user-drag: none;
    font-weight: bold;
    font-size: 18px;
    z-index: 2;

    .fullScreen {
      position: absolute;
      transform: scale(1);
      @include prefix(transition, transform 0.3s);
      &.zoomed {
        transform: scale(0);
      }
    }

    .fullScreenExit {
      position: absolute;
      transform: scale(0);
      @include prefix(transition, transform 0.3s);
      &.zoomed {
        transform: scale(1);
      }
    }
  }
}

.tag {
  @include zDepth1;
  background: #ffffff;
  border-radius: 4px;
  width: 100%;
  padding: 4px;
  margin-bottom: 4px;
  height: 76px;
  max-height: 76px;
  vertical-align: top;
  @include prefix(transition, height 0.3s, width 0.3s, max-height 0.3s);
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    @include zDepth2;
  }

  &:global(.placeholder) {
    background: $selectedRow !important;
    box-shadow: none !important;
  }

  > .topRowZoom {
    display: none;
    height: 100%;
  }

  > .bottomRowZoom {
    display: none;
    height: 100%;
  }

  > .regularRow {
    height: 100%;
  }

  &.zoom {
    &:global(.topRowTag) {
      max-height: 20px;
      .topRowContent {
        opacity: 1;
        height: 100%;
      }

      .bottomRowContent {
        opacity: 0;
        height: 0;
      }

      .regularRowContent {
        opacity: 0;
        height: 0;
      }
    }

    &:global(.bottomRowTag) {
      max-height: 20px;
      .topRowContent {
        opacity: 0;
        height: 0;
      }

      .bottomRowContent {
        opacity: 1;
        height: 100%;
      }

      .regularRowContent {
        opacity: 0;
        height: 0;
      }
    }

    &:global(.onDeckTag) {
      .topRowContent {
        opacity: 0;
        height: 0;
      }

      .bottomRowContent {
        opacity: 0;
        height: 0;
      }

      .regularRowContent {
        opacity: 1;
        height: 100%;
      }
    }
  }

  .topRowContent {
    @include prefix(transition, opacity 0.3s, height 0.3s);
    opacity: 0;
    height: 0;

    .row {
      height: 100%;
    }
  }

  .bottomRowContent {
    @include prefix(transition, opacity 0.3s, height 0.3s);
    opacity: 0;
    height: 0;
    .row {
      height: 100%;
    }
  }

  .regularRowContent {
    @include prefix(transition, opacity 0.3s, height 0.3s);
    opacity: 1;
    height: 100%;
  }

  &.urgent {
    background: $urgent;
  }

  &.dr1 {
    background: #47b3ff;
  }

  &.dr2 {
    background: #70b370;
  }

  &.dr3 {
    background: #fff52e;
  }

  &.dr4 {
    background: $lost;
  }

  .row {
    @include flexbox();
    @include justify-content(flex-start);
    @include align-items(center);
    @include flex-wrap(nowrap);
    white-space: nowrap;
    height: 33%;
    font-size: 90%;

    > .col1 {
      @include flex-grow(2);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.addColumnContent {
  height: 100%;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(column);

  :global(.ant-avatar) {
    margin-bottom: 16px;
    width: 80px;
    height: 80px;
    line-height: 80px;
    @include zDepth1;
    background: $primary;

    :global(.ant-avatar-string) {
      top: 0;
      height: 100%;
      @include flexbox();
      @include align-items(center);
    }
  }
}

.columnOptions {
  @include inline-flexbox();
  @include justify-content(center);
  @include align-items(center);
  vertical-align: middle;
}

.orderStamp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 8px);
}

.centerIcon {
  @include inline-flexbox();
  @include justify-content(center);
  @include align-items(center);
  vertical-align: bottom;
  margin-right: 4px;
}

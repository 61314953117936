@import "Styles/mixins.scss";

.fab {
  height: 56px !important;
  width: 56px;
  z-index: 2001 !important;
  @include inline-flexbox();
  @include justify-content(center);
  @include align-items(center);
  position: absolute !important;
  border: none !important;
  border-radius: 50% !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28) !important;
  cursor: pointer !important;
  outline: none !important;
  padding: 0 !important;
  -webkit-user-drag: none;
  font-weight: bold !important;
  font-size: 18px !important;
  z-index: 2;
  right: 24px;
  bottom: 77px;
}

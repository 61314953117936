@import "Styles/mixins.scss";

.uploadRow {
  @include flexbox();
  @include align-items(center);

  :global(.ant-spin-nested-loading) {
    width: 100%;
  }
}

@import "Styles/mixins.scss";

.partners {
  height: calc(100% - 22px - 17px);
  width: 100%;
  :global(.ant-carousel) {
    height: 100%;
    width: 100%;

    div {
      height: 100%;
      width: 100%;
    }

    .slide {
      height: 100%;
      width: 100%;
      padding: 24px;

      > img {
        display: block;
        margin: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}

.reviewWrapper {
  overflow: hidden;
  position: relative;

  > .innerWrapper {
    padding: 20px;
    overflow: hidden;
    width: 100%;

    a {
      cursor: default;
      text-decoration: none;
    }

    .avatar {
      position: relative;
      background-position: 50% 25%;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      width: 70px;
      height: 70px;
      border: 4px solid #999999;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .userName {
      color: #3d3f3e;
      color: rgba(255, 255, 255, 0.7);
      font-weight: 600;
      font-size: 20px;
      display: inline-block;
      width: auto !important;
    }

    .stars {
      display: inline-block;
      height: 16px !important;
      width: auto !important;
    }

    .review {
      margin-top: 0;
      color: rgba(0, 0, 0, 0.87);
      color: #ffffff;
      cursor: default;
      line-height: 1.5;
      word-break: break-word;
      font-size: 18px;
      margin-bottom: 10px;
      text-align: center;
      margin-top: 24px;
      height: unset;
    }

    .socialIcon {
      display: none;
      visibility: hidden;
      margin: 0 auto;
      margin-bottom: 10px;
      color: #999;

      .socialNumber {
        display: table-cell;
        vertical-align: middle;
        padding-left: 0;
        padding-right: 10px;
        color: #999;
        font-size: 12px;
      }
    }

    .sourceLogo {
      background-position: -24px 0px;
      background-image: url(https://embedsocial.com/cdn/rlogosn.png);
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      display: inline-block;
      margin-right: 48px;

      &.facebookLogo {
        background-position: 0px 0px;
      }
    }
  }
}

.mb1 {
  margin-bottom: 1px;
}

.mb3 {
  margin-bottom: 3px;
}

.titleContainer {
  width: 100%;
  height: 24px !important;
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
}

@import "Styles/variables.scss";

.statisticTitle {
  :global(.ant-statistic-title) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.selected {
  background: $selectedRow !important;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09) !important;
  border-color: transparent;
}

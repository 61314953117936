@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.complete {
  padding-top: 8px;
  padding-bottom: 8px;
  height: 41px !important;
  overflow: hidden;

  h4 {
    margin: 0;
  }

  :global(.ant-list-item-meta) {
    height: 25px !important;
    overflow: hidden;
    // &:last-child {
    //   text-align: right;
    //   max-width: 80px;
    //   margin: 0px;
    // }

    :global(.ant-list-item-meta-title) {
      color: #00c292;
    }
  }

  :global(.ant-list-item-meta-avatar) {
    overflow: hidden;
  }
}

.incomplete {
  padding-top: 8px;
  padding-bottom: 8px;
  height: 41px !important;
  overflow: hidden;

  h4 {
    margin: 0;
  }

  :global(.ant-list-item-meta) {
    height: 25px !important;
    overflow: hidden;
    :global(.ant-list-item-meta-title) {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  :global(.ant-list-item-meta-avatar) {
    overflow: hidden;
  }
}

.failed {
  padding-top: 8px;
  padding-bottom: 8px;
  height: 41px !important;
  overflow: hidden;

  h4 {
    margin: 0;
  }

  :global(.ant-list-item-meta) {
    height: 25px !important;
    overflow: hidden;
    // &:last-child {
    //   text-align: right;
    //   max-width: 80px;
    //   margin: 0px;
    // }

    :global(.ant-list-item-meta-title) {
      color: $lost;
    }
  }

  :global(.ant-list-item-meta-avatar) {
    overflow: hidden;
  }
}

.active {
  padding-top: 8px;
  padding-bottom: 8px;
  height: 41px !important;
  overflow: hidden;
  // @include prefix("animation", spin 1.3s infinite ease-in-out);

  h4 {
    margin: 0;
  }

  :global(.ant-list-item-meta) {
    height: 25px !important;
    overflow: hidden;
    // &:last-child {
    //   text-align: right;
    //   max-width: 80px;
    //   margin: 0px;
    // }

    :global(.ant-list-item-meta-title) {
      color: $primary;
    }
  }

  :global(.ant-list-item-meta-avatar) {
    overflow: hidden;
    svg {
      @include prefix("animation", spin 0.8s infinite ease-in-out);
    }
  }
}

@include keyframes(spin) {
  from {
    @include prefix("transform", rotate(0deg));
  }
  to {
    @include prefix("transform", rotate(360deg));
  }
}

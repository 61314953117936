@import "Styles/mixins.scss";

.tagContainer {
  @include flexbox();
  @include justify-content(flex-start);

  .tag {
    border: 1px solid #1890ff;
  }
}

.reportTitle {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  font-weight: 500;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum, "tnum"';
}

.outerReportContainer {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.reportContainer {
  width: calc(100% - 100px);
  height: 355px;
  display: inline-block;
  vertical-align: top;

  &.loading {
    height: 398px;
  }

  :global(.ant-skeleton) {
    height: 100%;

    :global(.ant-skeleton-content) {
      height: 100%;

      :global(.ant-skeleton-title) {
        margin: 0;
        width: 100%;
        height: 100%;
      }

      :global(.ant-skeleton-paragraph) {
        margin: 0 !important;
      }
    }
  }
}

@import "Styles/mixins.scss";

.searchRow {
  width: 100%;
  margin-right: 8px;
  @include inline-flexbox();
  @include align-items(center);

  &.admin {
    width: calc(100% - 68px);
  }
}

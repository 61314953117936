@import "Styles/mixins.scss";

.radioGroup {
  width: 100%;
  border-radius: 4px;
  margin-bottom: 0;
  :global(.ant-radio-group) {
    :global(.ant-radio-button-wrapper) {
      width: 33.33%;
      text-align: center;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

.deleteSize {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
}

.dumpsterHeader {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  margin-bottom: 8px;

  > h5 {
    margin-bottom: 0;
  }
}

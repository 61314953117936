@import "Styles/mixins.scss";

.imageContainer {
  height: 100%;
  width: 100%;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  overflow: hidden;
  > div {
    &:global(.react-transform-component) {
      height: 100%;
      width: 100%;

      :global(.react-transform-element) {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-wrap: unset;
        height: unset;
        width: unset;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  img {
    display: block;
    max-width: 100vw;
    max-height: 100vh;
    transform: rotate(0) scale(1) translate(0px, 0px);
    transform-origin: center;
  }

  .videoContainer {
    max-width: 100%;
    height: calc(100% - 52px) !important;
    width: 100% !important;
    margin-top: 52px;

    > video {
      outline: none !important;
    }
  }
}

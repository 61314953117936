@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.mobileListItem {
  @include flex-wrap(wrap);
  cursor: pointer;
  background: #ffffff;
  @include prefix(transition, background 0.3s);

  &:hover {
    background: $rowHoverSelect;
  }

  .customerId {
    @include flex(0 1 60px);
    padding-bottom: 3px;

    h4 {
      margin-bottom: 0;
    }
  }

  .status {
    @include flex(1 1 auto);
    padding-bottom: 3px;

    .statusTag {
      font-size: 9px;
      vertical-align: top;
      margin-top: 3px;
      line-height: unset;
    }
  }

  .sub {
    @include flex(1 1 auto);
    text-align: right;
    padding-bottom: 3px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .date {
    @include flex(1 1 auto);
    padding-bottom: 3px;
    text-align: right;
  }

  .break {
    @include flex(1 1 100%);
  }

  .fullLine {
    @include flex(1 1 100%);
    padding-bottom: 3px;

    :global(.ant-list-item-meta-description) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .sideBySide {
    @include flex(1 1 calc(50% - 8px));

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }

  .actions {
    @include flex(0 1 160px);
    white-space: nowrap;
    padding-top: 2px;

    :global(.ant-list-item-meta-description) {
      @include flexbox();
      @include align-items(center);
      @include justify-content(flex-end);

      .button {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        margin-left: 8px;
        background: transparent !important;
        padding: 0;

        svg {
          font-size: 32px !important;
        }
      }
    }
  }
}

.row {
  &:global(.ant-list-item) {
    cursor: pointer;
    background: #ffffff;
    @include prefix(transition, background 0.3s);

    &:hover {
      background: $rowHoverSelect;
    }

    &.selected {
      background: $selectedRow;
    }
  }

  &.danger {
    background: rgba(255, 112, 86, 0.5);
  }
}

.col {
  &:global(.ant-list-item-meta) {
    white-space: nowrap;
    display: block;
    flex: none;
    padding-left: 12px;

    &:first-child {
      padding-left: 0;
    }

    :global(.ant-list-item-meta-content) {
      width: unset;
      :global(.ant-list-item-meta-description) {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .scopeBadge {
      border-radius: 22px;
      width: 32px;
      text-align: center;
      display: inline-flex;
      @include justify-content(center);
      @include align-items(center);
    }
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.tableColumnHeaders {
  border-top: none !important;
  background: #ffffff;
  :global(.ant-list-item) {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-top: none !important;

    @include mq("xs") {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }

  &.borderBottom {
    border-bottom: 1px solid #d9d9d9;
  }

  &.noSideBorders {
    border-left: none;
    border-right: none;
  }
}

.colHeader {
  &:global(.ant-list-item-meta) {
    white-space: nowrap;
    display: block;
    flex: none;
    padding-left: 12px;

    &:first-child {
      padding-left: 0;
    }

    :global(.ant-list-item-meta-content) {
      width: unset;
    }

    h4 {
      margin: 0;
    }

    button {
      border: none !important;
      text-align: left;
      padding: 0;
      box-shadow: none !important;
      @include inline-flexbox();
      // @include justify-content(start);
      @include align-items(center);

      &:after {
        @include prefix(animation, none !important);
      }

      &.active {
        color: $primary;

        .sortIcon {
          margin-left: 4px;
          font-size: 16px !important;
          display: inline-block;
          transform-origin: center center;

          &.up {
            @include prefix(transform, rotate(180deg));
          }
        }
      }

      svg {
        display: none;
      }
    }
  }
}

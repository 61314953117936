@import "Styles/variables.scss";

// .mb16 {
//   margin-bottom: 16px;
// }

// .card {
//   padding: 12px;
// }

.stat {
  :global(.ant-statistic-skeleton) {
    padding-top: 0;
  }
  
  :global(.ant-statistic-title) {
    font-weight: 500;
    // color: #ffffff;
    .title {
      display: inline-block;
      width: 60%;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: top;

      &.trendNotIncluded {
        width: 100%;
      }
    }

    .trend {
      display: inline-flex;
      // vertical-align: top;
      width: 40%;
      text-align: right;
      justify-content: flex-end;
      align-items: center;

      // small {
      //   // margin-left: 8px;
      //   font-size: 14px;
      //   vertical-align: top;

      &.up {
        color: $green;
      }

      svg {
        height: 16px !important;
        width: 16px !important;
        font-size: 16px !important;
        vertical-align: top;
        margin-left: 4px;

        &.up {
          color: $green;
          fill: $green;
        }
      }
      // }

      

      :global(.ant-skeleton-content) {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        height: 22px;
      }
    }
  }

  :global(.ant-statistic-content) {
    // color: #ffffff;
    font-size: 18px;
  }

  :global(.ant-skeleton-content) {
    :global(.ant-skeleton-title) {
      margin-top: 8px;
    }
  }
}

.dashboardStatIcon {
  position: absolute;
  top: 15px;
  right: 5px;
}

.percentTag {
  border-radius: 12px !important;
  display: flex !important;
  align-items: center;
  margin-right: 0 !important;
  padding-right: 4px !important;
  // width: 75px;
  // text-align: center;
}

.lastYear {
  :global(.ant-skeleton-content) {
    :global(.ant-skeleton-title) {
      margin: 0;
      margin-top: 4px;
    }
  }
}

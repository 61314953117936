@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.altButtonContainer {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  margin-bottom: 12px;

  &.end {
    @include justify-content(flex-end);
  }

  button {
    padding: 0;
  }
}

.headerWithButton {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  margin-bottom: 12px;
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.scopeBadge {
  &:global(.ant-tag) {
    border-radius: 22px;
    width: 32px;
    text-align: center;
    display: inline-flex;
    @include justify-content(center);
    @include align-items(center);

    &.woNotCreated {
      opacity: 0.3;
    }
  }
}

.footer {
  background: #ffffff;
  @include zDepth0;
}

.mt8 {
  margin-top: 8px;
}

.scopeCard {
  background: #ffffff;
  @include prefix(transition, background 0.3s);

  &.urgent {
    background: $urgent;
  }

  &.selected {
    background: $selectedRow;

    :global(.ant-card-head) {
      border-bottom: 1px solid #dcdcdc;
    }
  }

  &:global(.hover) {
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    background: #efefef;
  }

  :global(.ant-card-head) {
    @include prefix(transition, border-bottom 0.3s);
  }

  :global(.ant-card-cover) {
    img {
      width: 100%;
      max-height: 150px;
      min-height: 150px;
      object-fit: cover;
    }
  }

  :global(.ant-card-meta-title) {
    margin-bottom: 4px !important;
  }

  * {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
}

.centerIcon {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
}

.dateStamp {
  position: absolute;
  top: 8px;
  left: 4px;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.85);
  z-index: 1;
  padding: 4px 8px;
  width: auto;
  border-radius: 6px;
  font-size: 10px;
}

.mapContainer {
  max-height: 100%;
  > div {
    height: 100%;
    > div {
      height: 100%;
    }
  }
}

.affix {
  margin-top: 24px;
  width: 100%;
  pointer-events: none;
  &:first-child {
    margin-top: 0px;
  }

  > div {
    width: 100%;
    > div {
      width: 100%;
      @include flexbox();
      @include justify-content(Center);
      @include align-items(center);
    }
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.albumCard {
  @include zDepth1;
  margin-bottom: 16px;

  &.global {
    background: $darkModeComponent;

    :global(.ant-card-meta-title) {
      color: #ffffff;
    }

    :global(.ant-card-meta-description) {
      color: $menuHeaderText;
    }
  }

  :global(.ant-card-cover) {
    height: 105px;
    max-height: 105px;
    min-height: 105px;
    overflow: hidden;

    :global(.ant-image) {
      height: 100%;

      :global(.ant-image-img) {
        height: 100%;
        max-height: 105px;
        object-fit: cover;
      }
    }
  }
}

.delete {
  float: right;
  color: $lost !important;
  fill: $lost !important;
  cursor: pointer;
}

.addImageContainer {
  overflow: hidden;
  max-height: 250px;
  border: 1px dashed #bfbfbf;

  &:hover {
    .addImage {
      color: #40a9ff;
      fill: #40a9ff;
    }
  }

  :global(.ant-card-cover) {
    overflow: hidden;
    height: 200px;

    :global(.ant-image) {
      height: 100%;

      :global(.ant-image-img) {
        height: 100%;
        max-height: 200px;
        object-fit: cover;
      }
    }
  }

  .addImage {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include flex-direction(column);
    height: 100%;
    font-size: 18px;

    @include prefix(transition, color 0.3s, fill 0.3s);
  }
}

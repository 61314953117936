@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.container {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(column);
  background: #f0f0f0;

  @include mq("xs") {
    display: block !important;
  }

  > img {
    width: 240px;
    margin-bottom: 24px;
    @include mq("xs") {
      display: none;
    }
  }

  .card {
    &:global(.ant-card) {
      @include zDepth1;
      padding: 0;
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      overflow: hidden;
      min-width: 434px;
      max-width: 434px;
      @include prefix(transition, background 0.3s);

      @include mq("xs") {
        height: 100%;
        width: 100%;
        min-width: 100%;
        border-radius: 0px !important;
        display: block !important;
      }

      &.accepted {
        background: $green;
        .text {
          color: #f1f1f1;
        }
      }

      &.declined {
        background: $lost;
        .text {
          color: #f1f1f1;
        }
      }

      > :global(.ant-card-body) {
        padding: 0;
        overflow: hidden;
        min-width: 100%;

        @include mq("xs") {
          height: 100%;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;
          overscroll-behavior-y: none;
          padding: 12px;
        }

        > img {
          width: 240px;
          display: block;
          margin: auto auto 24px;
          @include mq("xs", "min") {
            display: none;
          }
        }

        > .banner {
          width: 100%;
          padding: 24px;
          position: relative;
          z-index: 1;

          > h1 {
            text-align: center;
            margin: 0;
            @include prefix(transition, color 0.3s);

            > .success {
              font-size: 368px !important;
              color: rgba(255, 255, 255, 0.3) !important;
              position: absolute;
              right: -75px;
              top: -75px;
              z-index: 0;

              @include mq("xs") {
                left: calc(50% - 184px);
                right: calc(50% - 184px);
                top: -40px;
              }
            }
          }
        }

        .subTitle {
          padding: 24px;
          min-height: 114px;
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}

.progress {
  @include flexbox();
  @include align-items(center);
  margin-bottom: 12px !important;

  :global(.ant-progress-outer) {
    @include flex-grow(1);
    padding: 0;
    margin: 0;
    width: unset;
  }

  :global(.ant-progress-text) {
    width: unset;
  }
}

.imageRow {
  margin-bottom: 12px;

  .commentImageRow {
    @include flexbox();
    @include justify-content(flex-start);
    @include align-items(center);
    margin-bottom: 8px;

    .commentImage {
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      background: $dark;
      background-size: cover;
      background-position: center !important;
      background-repeat: no-repeat !important;
      border-radius: 8px;
      height: 50px;
      width: 50px;
      margin-right: 4px;
      overflow: hidden;
      @include zDepth1;

      > img {
        height: 40px;
      }

      > div {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
      }
    }
    > .delete {
      margin-left: auto;
      color: $lost !important;
      cursor: pointer;
    }
  }
}

@import "Styles/mixins.scss";

.actions {
  @include flexbox();
  @include justify-content(space-evenly);
  padding: 0;

  button {
    border: none !important;
    box-shadow: none !important;

    &:active,
    &:hover {
      border: none !important;
    }
  }
}

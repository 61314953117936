.leftSpacer {
  padding: 5px;
  text-align: left;
  font-size: 1.4rem;
  width: 39px;
  display: inline-block;
}

.rightSpacer {
  text-align: center;
  font-size: 1.4rem;
  padding: 10px;
  display: inline-block;
  width: 100px;
}

.topLabelsContainer {
  width: calc(100% - 139px);
  display: inline-block;
}

.labelContainer {
  text-align: center;
  font-size: 0.8rem;
  // padding: 10px;
  // width: 115.167px;
  width: calc(100% / 3);
  display: inline-flex;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  height: 0;
  vertical-align: top;
}

.conversionContainer {
  // min-width: 35px;
  // padding: 5px;
  position: relative;
  // width: 35px;
  text-align: center;
  font-size: 0.8rem;
  display: inline-block;
}

.arrowRight {
  width: 35px;
  position: absolute;
  top: -87px;
  left: -20px;
  // width: calc(100% - 10px);
  text-align: center;

  > span {
    display: inline-block;
    background-position: -79px -2302px;
    width: 18px;
    height: 12px;
    transform: rotate(180deg);
    margin: auto;
    opacity: 0.7;
    background-image: url(//img.zohocdn.com/crm/images/svgicons_162d5bc_.svg);
    background-repeat: no-repeat;
  }
}

.conversionNumber {
  overflow: visible;
  max-width: 100%;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  position: relative;
  z-index: 30;
  background: transparent;
  font-weight: 700;
  padding: 0;
  margin-left: 0;
  margin-top: 0;
  margin-right: 0;
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.soldSteps {
  @include justify-content(space-evenly);
}

.infoCircle {
  color: $lost !important;
  fill: $lost !important;
}

.imageRow {
  margin-bottom: 12px;

  .commentImageRow {
    @include flexbox();
    @include justify-content(flex-start);
    @include align-items(center);
    margin-bottom: 8px;

    .fileName {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @include flex-grow(1);
      width: calc(100% - 54px - 24px);
    }

    .commentImage {
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      background: $dark;
      background-size: cover;
      background-position: center !important;
      background-repeat: no-repeat !important;
      border-radius: 8px;
      height: 50px;
      width: 50px;
      min-width: 50px;
      margin-right: 4px;
      overflow: hidden;
      @include zDepth1;

      > img {
        height: 40px;
      }

      > div.imageCarousel {
        height: 50px;
        width: 50px;
        background-size: cover;
        background-position: center;
      }
    }

    > .delete {
      margin-left: auto;
      color: $lost !important;
      cursor: pointer;
    }
  }
}

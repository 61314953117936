@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.globalSearchDropResult {
  @include flexbox();
  :global(.gsLeft) {
    width: 32px;
    line-height: 44px;
    margin-right: 12px;
  }

  :global(.gsRight) {
    @include flex-grow(auto);

    :global(.gsTopLine) {
      font-weight: 600;
    }
  }
}

.globalSearch {
  border-radius: 4px !important;
  padding: 0 !important;
  background: #1b9dfa;
  transition: background 0.3s;

  &:global(.focus) {
    background: #ffffff;
  }

  :global(.ant-input-affix-wrapper),
  :global(.ant-input) {
    background: transparent;
    border: none;
  }

  :global(.ant-input-prefix) {
    color: #bfbfbf;
    margin-right: 8px;
  }
}

.globalSearchDropdown {
  border-radius: 4px !important;
  padding: 0 !important;
  background: #ffffff;
  transition: background 0.3s;

  &:global(.header) {
    background: #1b9dfa;
  }
  &:global(.header.focus) {
    background: #ffffff;
  }

  :global(.ant-input-affix-wrapper),
  :global(.ant-input) {
    background: transparent;
    border: none;
  }

  :global(.ant-input-prefix) {
    color: #bfbfbf;
    margin-right: 8px;
  }

  :global(.ant-select-item-group) {
    font-weight: bold;
    background: $menuHeader;
    color: $menuHeaderText;
    text-transform: uppercase;
  }

  :global(.ant-select-item-option) {
    border-bottom: 1px solid #ededed;
  }

  :global(.ant-select-dropdown-menu-item) {
    padding-left: 16px;
    border-bottom: 1px solid #ededed;
  }

  :global(.ant-select-dropdown-menu-item.show-all) {
    text-align: center;
    cursor: default;
  }

  :global(.ant-select-dropdown-menu) {
    max-height: 300px;
  }

  :global(.ant-select-selection__placeholder) {
    padding-left: 20px;
  }
}

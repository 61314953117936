:root {
  --dark: rgb(46, 49, 52);
  --green: #00c292;
  --greenHover: #02d4a0;
  --greenActive: #02b085;
}

.hide {
  display: none;
}

.p24 {
  padding: 24px;
}

.p16 {
  padding: 16px;
}

.p12 {
  padding: 12px;
}

.p8 {
  padding: 8px;
}

.py4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.px12 {
  padding-left: 12px;
  padding-right: 12px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb16 {
  margin-bottom: 16px;
}

.splash-container {
  height: 100%;
  width: 100%;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: #ffffff;

  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;

  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;

  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.splash-container > div {
  width: 300px;
  height: 143.8px;
  position: relative;
}

.rotate {
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center;
  opacity: 1;
  transform: scaleX(1);
  animation: rotate 2s infinite;
}

@keyframes rotate {
  0% {
  transform: scaleX(1);
  }
  50% {
    transform: scaleX(-1);
  }
  100% {
  transform: scaleX(1);
  }
}

.cancel-transitions,
.cancel-transitions > *,
.cancel-transitions * {
  -webkit-transition: all 0s !important;
  transition: all 0s !important;
  -webkit-animation: none !important;
  animation: none !important;
}

/* .cancel-animations .ant-modal-wrap,
.cancel-animations .ant-modal,
.cancel-animations .ant-modal-mask,
.cancel-animations .ant-modal-root, */
.cancel-animations .ant-drawer,
.cancel-animations .ant-drawer > * {
  -webkit-transition: all 0s !important;
  transition: all 0s !important;
  -webkit-animation: none !important;
  animation: none !important;
}

/* .cancel-animations .ant-modal,
.cancel-animations .ant-modal-mask {
  opacity: 1;
}

.cancel-animations .ant-modal-wrap {
  display: block !important;
  pointer-events: none;
} */

/* .cancel-animations .ant-modal-wrap .ignore-cancel-animations,
.cancel-animations .ant-modal.ignore-cancel-animations,
.cancel-animations .ant-modal-mask .ignore-cancel-animations,
.cancel-animations .ant-modal-root .ignore-cancel-animations {
  -webkit-transition: initial;
  transition: initial;
  -webkit-animation: initial;
  animation: initial;
}

.cancel-animations .ant-modal.ignore-cancel-animations,
.cancel-animations .ant-modal-mask .ignore-cancel-animations {
  opacity: initial;
}

.cancel-animations .ant-modal-wrap .ignore-cancel-animations {
  display: initial;
  pointer-events: initial;
} */

.public-background {
  background-color: #c3cbdc;
  background-image: linear-gradient(147deg, #c3cbdc 0%, #edf1f4 74%);
}
.hidden {
  display: none !important;
}
.text-center {
  text-align: center;
}
.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.z-depth-2 {
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 5px 0 rgba(0, 0, 0, 0.12),
    0 4px 1px -2px rgba(0, 0, 0, 0.2);
}

.z-depth_1 {
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.14), 0 -1px 5px 0 rgba(0, 0, 0, 0.12),
    0 -3px 1px -2px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overscroll-behavior: none;
}
.ant-layout {
  background: #ffffff;
}
.content-inner {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
  height: 100%;
  width: 100%;
}

/* 
  ScrollBar
*/
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome and Safari */
}
/* 
  Scrollbar
*/

/* 
  NAVIGATION
*/
.ant-layout-sider-children > .content-inner {
  height: calc(100% - 64px);
}
.ant-drawer.menu .content-inner {
  height: calc(100% - 89px);
}
.nav-header {
  border-bottom: 1px solid #eeeeee;
  transition: border-bottom 0.3s;
}
.nav-header.dark {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.ant-menu-dark .ant-menu-item-group-title {
  color: #ffffff !important;
}
/* 
  NAVIGATION
*/

/* 
  OVERIDES
*/
.ant-drawer-content {
  overflow: hidden;
}
.ant-select-dropdown {
  padding: 0;
}
.ant-picker,
.ant-input-affix-wrapper,
.ant-btn,
.ant-dropdown-menu,
.ant-message-notice-content,
.ant-card,
.ant-input,
.ant-input-affix-wrapper,
.ant-modal-content,
.ant-input-number,
.ant-radio-group,
.ant-tag,
.ant-select-dropdown,
.ant-select > .ant-select-selector,
.ant-select.ant-select-single > .ant-select-selector {
  border-radius: 4px;
}
.ant-modal-header {
  border-radius: 4px 4px 0 0;
}
.ant-btn.ant-btn-circle {
  border-radius: 50%;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding-bottom: 4px;
}
.ant-form-item {
  margin-bottom: 16px;
}
.ant-picker,
.ant-select,
.ant-input-number,
.ant-radio-group {
  width: 100%;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 28px;
}
.ant-layout-sider {
  transition: width 0.2s, max-width 0.2s, min-width 0.2s, flex 0.2s,
    background 0.3s;
}
.ant-drawer .ant-drawer-body {
  background: #ffffff;
  transition: background 0.3s;
}

.ant-drawer.dark .ant-drawer-body,
.ant-menu.ant-menu-submenu.ant-menu-dark,
.ant-layout-sider.ant-layout-sider-dark,
#navMenu {
  background: var(--dark) !important;
}
.ant-drawer.dark .ant-drawer-close {
  color: rgba(255, 255, 255, 0.65);
  transition: color 0.3s;
}
.ant-drawer.dark .ant-drawer-close:hover {
  color: #ffffff;
}
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  top: unset;
}
.ant-layout > .ant-alert.ant-alert-banner > .ant-alert-message {
  color: #ffffff;
}
.ant-message-notice-content {
  min-width: 25%;
  background: var(--dark);
  color: #ffffff;
}
.ant-notification-notice {
  background: var(--dark);
  color: #ffffff;
  border-radius: 4px;
}
.ant-notification-notice .ant-notification-notice-message {
  color: #ffffff;
}
.ant-notification-notice .ant-notification-notice-description {
  color: rgba(255, 255, 255, 0.65));
}
.ant-notification-notice .ant-notification-notice-close {
  color: #ffffff;
}
.ant-notification-notice .ant-notification-notice-close:hover {
  color: rgba(255, 255, 255, 0.65));
}
.ant-input-number-handler-wrap {
  display: none;
}
/* top line may break maps */
.gm-style-cc,
.gm-style a,
.gm-style > .gm-style-cc,
.gmnoprint > .gm-style-cc,
.gmnoprint.gm-style-cc {
  display: none !important;
}
.ant-message,
.ant-tooltip {
  z-index: 9999;
}
/* 
  OVERIDES
*/

/* 
  GREEN BUTTON
*/
.ant-btn-primary.green {
  color: #ffffff;
  background: var(--green);
  border-color: var(--green);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary.green:hover,
.ant-btn-primary.green:focus {
  color: #ffffff;
  background: var(--greenHover);
  border-color: var(--greenHover);
}
.ant-btn-primary.green:active {
  color: #ffffff;
  background: var(--greenActive);
  border-color: var(--greenActive);
}
.ant-modal-confirm-content {
  margin-left: 38px;
}
/* 
  GREEN BUTTON
*/

/* 
  EDIT MAP
*/
.map-container {
  /* width: "100%",
          height: "100%", */
  margin-bottom: 16px;
  border-radius: 4px;
  position: relative;
}
/* 
  EDIT MAP
*/

.drop180 .ant-dropdown-menu {
  max-height: 180px;
  overflow: auto;
}

.lazyload-wrapper {
  width: 100%;
}

.lazyload-wrapper > div {
  height: 100%;
}

.image100-wrapper,
.image10050-wrapper,
.image100-placeholder,
.image50-placeholder {
  height: 300px !important;
  min-height: 300px;
  width: 100%;
}

.image10050-wrapper {
  width: 50%;
}

.image100-wrapper > div,
.image50-wrapper > div {
  height: 100%;
}

.image50-wrapper {
  height: 150px !important;
  min-height: 150px;
  width: 100%;
}

.imageAvatar-wrapper {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.input-right,
.input-right input {
  text-align: right;
}

.ant-card-meta, .ant-card-meta-detail {
  width: 100%;
}
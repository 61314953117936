@import "Styles/mixins.scss";

.deleteButton {
  cursor: pointer;
  width: 100%;
  @include flexbox();
  @include justify-content(flex-end);
  @include align-items(center);
  margin-top: 4px;
}

.align {
  @include flexbox();
  @include justify-content(flex-end);
  @include align-items(center);
}

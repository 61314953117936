@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.container {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  @include flex-direction(column);

  padding: 24px;
  background: #ffffff;
}

@import "Styles/variables.scss";

.reportTitle {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  font-weight: 500;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum, "tnum"';
  padding-left: 12px;
  padding-right: 12px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;

  :global(.ant-skeleton-content) {
    :global(.ant-skeleton-title) {
      margin: 0;
    }
  }

  .trend {
    display: inline-flex;
    // vertical-align: top;
    width: 40%;
    text-align: right;
    justify-content: flex-end;
    align-items: center;

    // small {
    //   // margin-left: 8px;
    //   font-size: 14px;
    //   vertical-align: top;

    &.up {
      color: $green;
    }

    svg {
      height: 16px !important;
      width: 16px !important;
      font-size: 16px !important;
      vertical-align: top;
      margin-left: 4px;

      &.up {
        color: $green;
        fill: $green;
      }
    }
    // }

    :global(.ant-skeleton-content) {
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      height: 22px;
    }
  }
}

.tableHeader {
  background: $darkModeComponent;

  h4 {
    color: #ffffff;
    margin: 0;
  }
}

.reportContainer {
  max-width: 100%;
  overflow: auto;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.tableFooter {
  background: $primary;

  h4 {
    color: #ffffff;
    margin: 0;
  }
}

.percentTag {
  border-radius: 12px !important;
  // width: 75px;
  text-align: right;
  display: inline-flex !important;
  justify-self: flex-end;
  align-items: center;
  margin-right: 0 !important;
  padding-right: 4px !important;

  svg {
    height: 16px !important;
    width: 16px !important;
    font-size: 16px !important;
    vertical-align: top;
    margin-left: 4px;
  }
}

.tableBody {
  :global(.ant-skeleton-content) {
    :global(.ant-skeleton-title) {
      margin: 0;
      margin-top: 4px;
    }
    :global(.ant-skeleton-paragraph) {
      margin: 0 !important;
    }
  }

  :global(.ant-list-item-meta-description) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

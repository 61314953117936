@import "Styles/mixins.scss";

.tableContainer {
  @include flexbox();
  @include flex-direction(column);
  height: 100%;
  width: 100%;
  :global(.ant-list) {
    border-radius: 0;
  }

  &:global(.sider) {
    &:global(.collapsed) {
      :global(.tableBody) {
        width: calc(100% - 80px);
      }
    }

    :global(.tableBody) {
      width: calc(100% - 240px);
    }
  }

  &:global(.filter) {
    :global(.tableBody) {
      height: calc(100% - 57px - 37px - 37px - 64px);
    }
  }

  &:global(.isMobile) {
    :global(.tableBody) {
      height: calc(100% - 95px - 27px);
    }

    &:global(.filter) {
      :global(.tableBody) {
        height: calc(100% - 95px - 37px - 27px);
      }
    }
  }
}

@import "Styles/mixins.scss";

.actionRow {
  @include flexbox();
  @include justify-content(flex-start);
  @include align-items(center);
  padding-left: 46px;
  font-size: 12px;

  &.updateActionRow {
    padding-left: 6px;
  }

  &._global {
    .commentActions {
      button {
        color: rgba(255, 255, 255, 0.7);

        &:hover {
          color: #ffffff;
        }
      }
    }
  }

  .timeStamp {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .commentActions {
    white-space: nowrap;
    flex-grow: 1;
    button {
      font-size: 12px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.6);
      margin-right: 8px;
      min-width: 60px;
    }
  }
}

.likesContainer {
  @include flexbox();
  @include justify-content(flex-end);
  @include align-items(center);
  @include flex-grow(1);
  min-width: 26px;
  margin-right: 8px;
}

.pdateActionRow {
  @include flexbox();
  @include justify-content(flex-end);
  @include align-items(center);
  font-size: 12px;
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.column {
  display: inline-block;
  @include zDepth1;
  width: 320px;
  height: 100%;
  vertical-align: top;
  background: rgb(240, 242, 245);

  &:last-child {
    margin-right: 0;
  }

  .empty {
    background: $green;
  }

  .header {
    padding: 12px;
    height: 46px;
    background: #ffffff;
    z-index: 1;
    position: relative;
    @include zDepth1;

    h4 {
      font-weight: 800;
      text-transform: uppercase;
    }
  }

  .content {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: none;
    height: calc(100% - 46px);
    width: 100%;
  }
}

.tag {
  &:global(.ant-card) {
    overflow: hidden;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 12px;

    :global(.ant-card-body) {
      > div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

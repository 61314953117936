@import "Styles/mixins.scss";

.resizeHandle {
  position: absolute;
  background: none rgb(255, 255, 255);
  border: 0px;
  margin: 10px;
  padding: 0px;
  cursor: grab;
  user-select: none;
  border-radius: 2px;
  height: 40px;
  width: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  overflow: hidden;
  bottom: 0px;
  right: 0px;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);

  &:focus,
  &:active {
    cursor: grabbing !important;
  }
}

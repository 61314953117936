@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.dragContainer {
  background: #ffffff;
  padding-left: 0px;

  &:global(.placeholder) {
    background: $selectedRow !important;
    @include zDepth2;
  }

  :global(.ant-row.ant-form-item) {
    margin: 0;
    padding-bottom: 16px;
  }

  .dragHandle {
    cursor: grab;
    width: 100%;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
  }

  .deleteButton {
    cursor: pointer;
    width: 100%;
    @include flexbox();
    @include justify-content(flex-end);
    @include align-items(center);
  }
}

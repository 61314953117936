$primary: #108ee9;
$lost: #ff7056;
$lostDisabled: rgba(255, 112, 86, 0.6);
$dark: rgb(46, 49, 52);
$green: #00c292;
$greenHover: #02d4a0;
$greenActive: #02b085;
$hold: #999999;
$closedOut: #00c292;
$darkModeComponent: #424242;
$gold: #faad14;
$urgent: rgba(255, 165, 0, 0.5);

$rowHoverSelect: whitesmoke;
// $rowHoverSelect: rgba(0, 0, 0, 0.018);

$selectedRow: rgba(16, 142, 233, 0.3);

$menuHeader: #999999;
$menuHeaderText: rgba(255, 255, 255, 0.85);

$actionRedColor: #f5222d;
$actionRed: #f5f5f5;
$actionRedHover: #ff7056;

$actionGreenColor: #399f4c;
$actionGreen: #ecf8ee;
$actionGreenHover: #00c292;

$actionGrayColor: #808080;
$actionGray: #e6e6e6;
$actionGrayHover: #999999;

.gridContainer {
  width: calc(100% + 24px);
  margin-left: -12px;
  margin-right: -12px;
  height: 300px;
  overflow: hidden;
}

.grid {
  text-align: center;
  width: 100%;
  height: 100%;
}

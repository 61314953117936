@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.commentImage {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  background: $dark;
  background-size: cover;
  background-position: center !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
  border-radius: 8px;
  height: 50px;
  width: 50px;
  margin-right: 4px;
  overflow: hidden;
  @include zDepth1;

  > img {
    height: 40px;
  }

  > div {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.removeRow {
  margin-right: 0 !important;
  color: $lost !important;
}

.materialRow {
  border-radius: 4px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background: transparent;
  @include prefix(transition, background 0.3s);
  padding: 2px 0px !important;
  &:hover {
    background: $rowHoverSelect;
  }

  &:global(.active) {
    background: $selectedRow;
  }
}

.rowSelected {
  border-radius: 4px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background: $rowHoverSelect;
  padding: 2px 0px !important;
}

.checkbox {
  height: calc(100% - 12px);
  > :global(.ant-col) {
    height: 100%;
    > :global(.ant-form-item-control-input) {
      height: 100%;
    }
  }

  :global(.ant-checkbox) {
    margin-left: calc(50% - 6px);
    border-radius: 50%;

    @include mq(sm) {
      margin-left: 50%;
    }

    &:hover::after {
      border-radius: 50%;
    }
  }

  input {
    height: 24px !important;
    width: 24px !important;
    border-radius: 50%;
    outline: none;
  }

  :global(.ant-checkbox-inner) {
    height: 24px;
    width: 24px;
    border-radius: 50%;

    &::after {
      left: 30%;
    }
  }
}

.button {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
}

.updatedValue {
  color: $primary !important;
  * {
    color: $primary !important;
  }
}

.updatedDisabled {
  input[disabled] {
    color: rgba(16, 142, 233, 0.75) !important;
    background: #87ccff !important;
  }
}

@import "Styles/mixins.scss";

.container {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  height: 100%;
  width: 100%;
}

.card {
  @include zDepth1;
  min-width: 300px;

  :global(.ant-card-actions) {
    > li {
      height: 48px;
      margin: 0;

      > span {
        height: 100%;

        > button {
          height: 100%;
        }
      }
    }
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.mb24 {
  margin-bottom: 24px;
}

.button {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);

  svg {
    margin-right: 8px;
  }
}

.reviewSection {
  height: 188px;
  @include prefix(transition, height 0.3s);

  &.collapsed {
    height: 32px;

    .reviewSectionChild {
      .collapseTrigger {
        > svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .reviewSectionChild {
    height: 100%;
    overflow: hidden;

    .collapseTrigger {
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      z-index: 1;

      > svg {
        @include prefix(transition, transform 0.3s);
        transform: rotate(0deg);
      }
    }

    .reviewNotes {
      opacity: 1;
      @include prefix(transition, opacity 0.3s);

      &.collapsed {
        opacity: 0;
      }
    }
  }
}

.fab {
  height: 56px;
  width: 56px;
  z-index: 2001;
  @include inline-flexbox();
  @include justify-content(center);
  @include align-items(center);
  position: absolute;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  cursor: pointer;
  outline: none;
  padding: 0;
  -webkit-user-drag: none;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
  right: 24px;
  bottom: 265px;
  @include prefix(transition, bottom 0.3s);

  &.mold {
    bottom: 345px;

    &.collapsed {
      bottom: 189px;
    }
  }

  &.collapsed {
    bottom: 109px;
  }
}

.scopeCol {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.activity {
  width: 160px;
  margin-bottom: 16px;
  overflow: hidden;
  cursor: pointer;
  :global(.ant-card-cover) {
    min-height: 160px;
    max-height: 160px;
    overflow: hidden;
    background: $dark;

    img,
    object {
      object-fit: cover;
    }
  }
}

.pdf {
  height: 100%;
  width: 100%;
  padding: 8px;
  min-height: 160px;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(column);

  > img {
    max-width: 45px;
    display: block;
  }

  > button {
    display: block;
  }

  > div {
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    margin-top: 8px;
  }
}

.image {
  min-height: 160px;
  height: 100%;
  width: 100%;
}

.deleteText {
  color: $lost;
}

.video {
  height: 100%;
  width: 100%;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(column);
  padding: 8px;

  > img {
    max-width: 45px;
    display: block;
  }

  > div {
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    margin-top: 8px;
  }
}

.container {
  padding: 24px;
}

.detailsContainer {
  padding: 12px 24px 24px 24px;
}

.actionButtons {
  height: 56px;
  margin-bottom: 12px;
  @include flexbox();
  @include justify-content(space-evenly);

  button {
    height: 100%;
    width: 100%;
    border: none !important;
    box-shadow: none !important;

    &::after,
    &:active,
    &:hover {
      border: none !important;
      @include prefix(animation, none !important);
    }
  }
}

.red {
  color: $lost;
}

.green {
  color: $green;
}

.actionContainer {
  @include flexbox();
  @include justify-content(space-between);
}

.redBtn {
  @include prefix(transition, background 0.3s, color 0.3s, border-color 0.3s);
  background-color: $actionRed;
  color: $actionRedColor;
  border-color: #d9d9d9;

  &:hover,
  &:active,
  &:focus {
    background-color: $actionRedHover;
    border-color: $actionRedHover;
    color: #ffffff;
  }
}

.grayBtn {
  @include prefix(transition, background 0.3s, color 0.3s, border-color 0.3s);
  background-color: $actionGray;
  color: $actionGrayColor;
  border-color: #bfbfbf;

  &:hover,
  &:active,
  &:focus {
    background-color: $actionGrayHover;
    border-color: $actionGrayHover;
    color: #ffffff;
  }
}

.greenBtn {
  @include prefix(transition, background 0.3s, color 0.3s, border-color 0.3s);
  background-color: $actionGreen;
  color: $actionGreenColor;
  border-color: #b5e3bd;

  &:hover,
  &:active,
  &:focus {
    background-color: $actionGreenHover;
    border-color: $actionGreenHover;
    color: #ffffff;
  }
}

.withIcon {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);

  button {
    &:global(.ant-btn) {
      padding: 0;
      min-height: 24px;
      height: 24px;
      // min-width: 24px;
      width: 24px;
    }
  }
}

h4 {
  margin-bottom: 1px;
}

.row {
  margin-bottom: 1em;
}

.imageBody {
  height: calc(100% - 64px - 34px);
  position: fixed;
  padding: 0 !important;
  @include prefix(transition, height 0.3s);
  width: 100%;
  overflow: hidden;

  &.selecting {
    height: calc(100% - 64px - 34px - 53px);
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.actions {
  @include flexbox();
  @include justify-content(space-evenly);
  padding: 0;

  &._global {
    background: $dark;
    border-top: 1px solid #424242;

    button {
      background: $dark;

      color: rgba(255, 255, 255, 0.9);

      &:hover {
        color: #40a9ff;
      }
    }
  }

  button {
    border: none !important;
    box-shadow: none !important;

    &::after,
    &:active,
    &:hover {
      border: none !important;
      @include prefix(animation, none !important);
    }
  }

  :global(.ant-dropdown-menu) {
    max-height: 180px;
    overflow: auto;
  }
}

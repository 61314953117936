@import "Styles/mixins.scss";

.scopeBadge {
  &:global(.ant-tag) {
    border-radius: 22px;
    width: 32px;
    text-align: center;
    display: inline-flex;
    @include justify-content(center);
    @include align-items(center);

    &.mobile {
      border-radius: 50%;
      height: 33px;
      width: 33px;
    }

    &.woNotCreated {
      opacity: 0.3;
    }
  }
}

.container {
  //   width: 100%;
  max-width: 100px;
  width: 100px;
  height: 398px;
  display: inline-block;
  vertical-align: top;
  text-align: center;

  :global(.ant-skeleton) {
    height: 100%;

    :global(.ant-skeleton-content) {
      height: 100%;

      :global(.ant-skeleton-title) {
        margin: 0;
        width: 100%;
        height: 100%;
      }

      :global(.ant-skeleton-paragraph) {
        margin: 0 !important;
      }
    }
  }
}

.innerContainer {
  height: 398px;
  min-width: 100px;
  position: relative;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--br_ccc);
    position: absolute;
    bottom: -5px;
    left: -5.27px;
  }
}

.dealsIn {
  margin-bottom: 5px;
  padding: 0;
  margin-left: 0;
  margin-top: 0;
  margin-right: 0;
  position: relative;
  top: 0;
}

.topSpacer {
  margin-bottom: 5px;
  position: relative;
  //   min-width: 100px;
  display: inline-block;
  width: 0;
  height: calc(100% - 70px);
  border-left: 1px solid #ccc;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--br_ccc);
    position: absolute;
    bottom: -5px;
    left: -5.27px;
  }
}

.centerSpacer {
  top: calc(50% - 43px);
  width: 100%;
  position: absolute;
  background: #ffffff;
}

.centerNumber {
  line-height: 1.2em;
  padding-top: 5px;
  font-size: 1.6rem;
  margin: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.centerLabel {
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 0.7rem;
  padding: 0;
  margin-left: 0;
  margin-top: 0;
  margin-right: 0;
}

#segmentationTable {
  height: 100%;
  padding-bottom: 10px;
  box-sizing: border-box;
}

#segmentationTable > table {
  height: 100%;
}

.w100per {
  width: 100%;
}

.aligncenter,
.taCenter,
.txt-ctr,
.vpvl {
  text-align: center;
}

.pR,
.pRealtive,
.posRel,
.posrel {
  position: relative;
}

.AD_standard_drop_arrow_cont {
  min-width: 100px;
}

.pR,
.pRealtive,
.posRel,
.posrel {
  position: relative;
}

.mB5 {
  margin-bottom: 5px;
}

dd,
dl,
dt,
p {
  margin: 0;
  padding: 0;
}

.pR,
.pRealtive,
.posRel,
.posrel {
  position: relative;
}

.dIB,
.dInlineBlock {
  display: inline-block;
}

.AD_funnel_downArrow:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--br_ccc);
  position: absolute;
  bottom: -5px;
  left: -5.27px;
}

.pA,
.pAbsolute {
  position: absolute;
}

.whiteBg {
  background: var(--bg_white) !important;
}

.crm-font-regular,
.ProximaNovaRegular,
.ProximaNovaLight,
.ProximaNovaThin {
  font-family: var(--crm-font-regular);
}

.f24 {
  font-size: 2.4rem;
}

.pT5 {
  padding-top: 5px;
}

.ttUpper,
.ttUppercase,
.uppercase {
  text-transform: uppercase;
}

.f12 {
  font-size: 1.2rem;
}

.AD_createAnomaly_table .AD_anomaly_td,
.funnelTh.p5,
.targetIndividualTable .innerTd {
  padding: 5px;
}

.alignLeft,
.alignleft,
.tal {
  text-align: left;
}

.funnelTh {
  text-align: center;
  font-size: 1.4rem;
  padding: 10px;
}

.w50 {
  width: 50px;
}

.segmentNotPresent .funnelTh.w35 {
  min-width: 35px;
}

.w35 {
  width: 35px;
}

.AD_standard_funnel_arrow {
  position: absolute;
  top: -70px;
  width: calc(100% - 10px);
  text-align: center;
}

.customModule_Builder.AD_GridContainer,
td.funnelTh p.funnelPercentVal {
  overflow: visible;
}

.funnelPercentVal,
.graphColVal {
  color: var(--111);
  font-weight: 700;
}

.funnelPercentVal {
  position: relative;
  z-index: 30;
  background: var(--bg_white);
}

.funnelNextIcon {
  background-position: -79px -2302px;
  width: 18px;
  height: 12px;
  transform: rotate(180deg);
  margin: auto;
  opacity: 0.7;
}

.svgIcons {
  background-image: url(//img.zohocdn.com/crm/images/svgicons_162d5bc_.svg);
  background-repeat: no-repeat;
}

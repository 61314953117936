@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.moreOptions {
  background: transparent;

  :global(.ant-drawer-content-wrapper) {
    background: transparent;
    box-shadow: none !important;

    :global(.ant-drawer-content) {
      background: transparent;

      .action {
        text-align: center;
        font-size: 14px;
        padding: 12px 0;
        border-bottom: 1px solid rgba(250, 250, 250, 0.1);
        background: $dark;
        color: #ffffff;
        cursor: pointer;

        &.delete {
          color: $lost !important;
        }

        &:first-child {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-top: none !important;
        }

        &:nth-last-child(2) {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          border-bottom: none !important;
        }
      }

      .cancel {
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        padding: 12px 0;
        border-radius: 10px;
        margin-top: 8px;
        margin-bottom: 8px;
        background: $dark;
        color: #ffffff;
      }
    }
  }
}

@import "Styles/mixins.scss";

.tableHeader {
  &:global(.ant-list) {
    background: rgb(235, 235, 235);
    // border-bottom: none !important;

    :global(.ant-list-item) {
      padding-left: 24px;
      padding-right: 24px;
      // border-bottom: 1px solid #d9d9d9 !important;

      @include mq("xs") {
        padding-left: 12px !important;
        padding-right: 12px !important;
      }

      &.mobileSearch {
        padding-top: 0;
        padding-bottom: 6px;
      }
    }
  }

  &.bordered {
    &:global(.ant-list) {
      background: rgb(235, 235, 235);
      border-bottom: none !important;

      :global(.ant-list-item) {
        padding-left: 24px;
        padding-right: 24px;
        border-bottom: 1px solid #d9d9d9 !important;

        @include mq("xs") {
          padding-left: 12px !important;
          padding-right: 12px !important;
        }

        &.mobileSearch {
          padding-top: 0;
          padding-bottom: 6px;
        }
      }
    }
  }
}

.dropdown {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: uppercase;
  padding: 0 !important;
  &:hover,
  &:active,
  &:focus {
    background: transparent !important;
  }
  &:focus,
  &:hover {
    color: #40a9ff !important;
  }
  &:active {
    color: #096dd9 !important;
  }
}

.whiteText {
  :global(.ant-list-item-meta-description) {
    color: #ffffff;
  }
}

@import "Styles/mixins.scss";

.container {
  position: relative;
  height: 100%;
}

.selectedPinsCount {
  position: absolute;
  left: 76px;
  top: 24px;
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.7);
}

.mapIconCircle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  transition: all 0.3s;
  position: absolute;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);

  &:hover {
    background: rgba(255, 255, 255, 0.95);
  }
}

.mapButtonPin {
  top: 24px;
  left: 24px;
}

.mapButtonFilter {
  top: 24px;
  right: 24px;
}

.mapButtonLocation {
  top: 88px;
  left: 24px;
}

.icon {
  width: 20px;
}

.loadingContainer {
  @include prefix(transition, opacity 0.3s);
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(250, 161, 161, 0.15);
  pointer-events: none;
  &.loading {
    opacity: 1;
    pointer-events: all;
  }
}

@import "Styles/variables.scss";

.menu {
  overflow: hidden;
  padding: 0 !important;
  :global(.ant-dropdown-menu-item-group-title) {
    background: $menuHeader;
    color: $menuHeaderText;
  }

  :global(.ant-dropdown-menu-item-group-list) {
    margin: 0;
    padding: 0;

    > li {
      padding: 5px 20px;
    }
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

h4.relatedTitles {
  font-size: 16px;
  color: $dark;
}

.relatedContainer {
  &:global(.ant-card) {
    background-color: #ffffff;
    @include zDepth1;
    margin-bottom: 16px;
    border-radius: 4px;
    overflow: hidden;

    :global(.ant-card-head) {
      margin: 0;
      background: #ffffff;
      min-height: unset;

      @include prefix(transition, background 0.3s);

      .h4 {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 600;
      }

      :global(.ant-card-head-title),
      :global(.ant-card-extra) {
        padding-bottom: 0px;
      }
    }

    .subHeader {
      @include flexbox();
      @include justify-content(space-evenly);
      @include align-items(center);

      padding: 0px 24px 16px 24px;

      > div {
        color: rgba(0, 0, 0, 0.43);

        &:first-child {
          flex: 1 1;
        }

        &:last-child {
          text-align: right;
          float: right;
          margin-left: 12px;
        }
      }
    }

    .panelDetail {
      float: right;
    }

    :global(.ant-card-actions) {
      > li {
        height: 48px;
        margin: 0;

        > span {
          height: 100%;

          > button {
            height: 100%;
          }
        }
      }
    }

    :global(.ant-list-item) {
      &:first-child {
        border-top: 1px solid #f0f0f0;
      }
    }

    .list {
      :global(.ant-collapse-content-box) {
        padding: 0 !important;
      }
    }
  }
}

.complete {
  padding-top: 8px;
  padding-bottom: 8px;

  h4 {
    margin: 0;
  }

  :global(.ant-list-item-meta) {
    &:last-child {
      text-align: right;
      max-width: 80px;
      margin: 0px;
    }

    :global(.ant-list-item-meta-title) {
      color: #00c292;
    }

    :global(.ant-tag) {
      margin: 0;
    }
  }
}

.uncomplete {
  padding-top: 8px;
  padding-bottom: 8px;

  h4 {
    margin: 0;
  }

  :global(.ant-list-item-meta) {
    :global(.ant-list-item-meta-title) {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}

.failed {
  padding-top: 8px;
  padding-bottom: 8px;

  h4 {
    margin: 0;
  }

  :global(.ant-list-item-meta) {
    &:last-child {
      text-align: right;
      max-width: 80px;
      margin: 0px;
    }

    :global(.ant-list-item-meta-title) {
      color: $lost;
    }

    :global(.ant-tag) {
      margin: 0;
    }
  }
}

.button {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
}

.mr8 {
  margin-right: 8px;
}

.woHeader {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  padding-top: 8px;
  padding-bottom: 8px;
  background: rgba(16, 142, 233, 0.75);

  h4 {
    margin: 0;
    color: #ffffff;
  }

  :global(.ant-list-item-meta) {
    text-align: center;
  }
}

@import "Styles/mixins.scss";

.state {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  margin-bottom: 12px;
  height: 120px;
  width: 100%;

  object {
    height: 120px;
  }
}

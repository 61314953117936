@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.activity {
  margin-bottom: 8px !important;
  @include zDepth1;

  .body {
    padding: 4px 12px;

    .content > span {
      white-space: pre-wrap;
    }
  }
}

.affix {
  margin-top: 24px;
  margin-bottom: 8px;
  width: 100%;
  @include flexbox();
  @include justify-content(Center);
  @include align-items(center);

  &:first-child {
    margin-top: 0px;
  }

  > div {
    width: 100%;
  }
}

.dateStamp {
  margin-bottom: 8px;
  width: 50%;
  min-width: 180px;

  @include flexbox();
  @include justify-content(Center);
  @include align-items(center);
  background: $dark;
  color: rgba(255, 255, 255, 0.85);
  border-radius: 20px;
  padding: 2px 8px;
  font-size: 11px;
  @include zDepth1;
  margin: auto;
}

.hashtagSearch {
  &:global(.ant-select) {
    margin-bottom: 8px;
    > div {
      border-radius: 20px !important;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    }
  }
}

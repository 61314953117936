@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.mobileScope {
  &:global(.ant-card) {
    @include zDepth1;

    :global(.ant-card-body) {
      padding: 12px;
    }

    :global(.ant-card-cover) {
      position: relative;
      max-height: 120px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      :global(.ant-image) {
        width: 100%;
        :global(.ant-image-img) {
          height: 100%;
          width: 100%;
          max-height: 120px;
          object-fit: cover;
        }
      }
    }

    :global(.ant-card-actions) {
      > li {
        margin: 8px 0;
      }
    }

    .moreVert {
      &:global(.ant-btn) {
        position: absolute;
        right: 6px;
        top: 6px;
        z-index: 1;
        width: 40px;
      }
    }
  }
}

.dateStamp {
  position: absolute;
  top: 4px;
  left: 4px;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.85);
  z-index: 1;
  padding: 4px 8px;
  width: auto !important;
  border-radius: 6px;
  font-size: 10px;
}

.mobilePoCustomerName {
  position: absolute;
  bottom: -12px;
  left: 0px;
  background: $primary;
  z-index: 1;
  padding: 4px 12px;
  padding-left: 12px;
  width: auto !important;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  > h5 {
    &:global(.ant-typography) {
      color: rgba(255, 255, 255, 0.85);
    }
  }
}

.bottomBtn {
  &:global(.ant-btn) {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    padding: 6.4px 15px;
    width: 100%;

    &.installStatus {
      font-size: 30px;
      font-weight: 400;
    }
  }
}

.detailLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.materialDateStamp {
  position: absolute;
  top: 4px;
  right: 4px;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.85);
  z-index: 1;
  padding: 4px 8px;
  width: auto !important;
  border-radius: 6px;
  font-size: 10px;
}

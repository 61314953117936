@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.transactionsCard {
  @include zDepth1;
}

.transationsList {
  :global(.ant-list-item) {
    padding: 12px;

    &:last-child {
      &:global(.ant-list-item) {
        border-bottom: 1px solid #f0f0f0;
      }
    }

    :global(.ant-list-item-meta) {
      :global(.ant-list-item-meta-title) {
        margin-bottom: 0;
      }

      &:last-child {
        text-align: right;

        &.fullWidth {
          text-align: left;
        }
      }
    }
  }
}

@import "Styles/mixins.scss";

.reportTitle {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  font-weight: 500;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum, "tnum"';
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
}

.reportContainer {
  height: 295px;
  max-width: 100%;

  :global(.highcharts-container) {
    width: 100% !important;
  }

  :global(.highcharts-root) {
    width: 100% !important;
  }

  :global(.ant-skeleton) {
    height: 100%;

    :global(.ant-skeleton-content) {
      height: 100%;

      :global(.ant-skeleton-title) {
        margin: 0;
        width: 100%;
        height: 100%;
      }

      :global(.ant-skeleton-paragraph) {
        margin: 0 !important;
      }
    }
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.body {
  @include flexbox();
  @include flex-direction(column);
  @include flex-wrap(nowrap !important);
  width: 100%;
  height: 100%;
}

.list {
  li {
    height: 57px;
    background: #ffffff;
    &:global(.list-item) {
      border-bottom: 1px solid #f0f0f0 !important;
      padding-left: 24px;
      padding-right: 24px;
      background: #ffffff;
      @include prefix(transition, background 0.3s);
    }

    .col1 {
      max-width: 120px;

      :global(.ant-list-item-meta-description) {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &:global(.list-header) {
      text-transform: uppercase;
      padding-left: 24px;
      padding-right: 24px;
      background: $menuHeader;

      :global(.ant-list-item-meta-title) {
        color: #ffffff;
        margin-bottom: 0;
      }
    }
  }
}

@import "Styles/mixins.scss";

.actionButtons {
  height: 56px;
  margin-bottom: 12px;
  margin-top: 12px;
  margin-left: -16px;
  margin-right: -16px;
  width: calc(100% + 32px);
  @include flexbox();
  @include justify-content(space-evenly);

  &.narrow {
    margin-left: -4px;
    margin-right: -4px;
    width: calc(100% + 8px);
  }

  button {
    height: 100%;
    width: 25%;
    padding: 0;
    border: none !important;
    box-shadow: none !important;

    &::after,
    &:active,
    &:hover {
      border: none !important;
      @include prefix(animation, none !important);
    }
  }
}

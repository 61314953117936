@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.header {
  &:global(.ant-layout-header) {
    // position: relative;
    background-color: $primary;
    color: #ffffff;
    z-index: 8;
    width: 100%;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    @include prefix(transition, all 0.3s);
    padding: 0 24px;
  }

  &.searchFocus {
    opacity: 0.8;
  }

  :global(.actions) {
    cursor: pointer;
    @include prefix(transition, color 0.3s);
    border: none;
  }

  :global(.actions.last) {
    margin-right: 0;
  }

  @include mq("sm") {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.headerLeft {
  height: 64px;
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  @include flex-grow(1);
  white-space: nowrap;
  z-index: 2;
}

.headerMiddle {
  height: 64px;
  padding-right: 32px;
  @include flex-grow(2);
  z-index: 2;

  @include mq("sm") {
    display: none;
  }
}

.headerRight {
  // width: 240px;
  // min-width: 240px;
  max-width: 240px;
  @include flex-grow(1);
  z-index: 2;
  // right: 0;
  // position: relative;

  > div {
    height: 64px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-end);

    button {
      width: 24px;
      padding: 0;
      box-shadow: none !important;
      border: none !important;
      background: transparent !important;
      @include prefix(transition, transform 0.3s);

      &.updateAvailable {
        width: unset !important;
        margin-right: 0 !important;
        color: #ffffff !important;

        span {
          display: inline-block;
          margin-left: 8px;
          color: #ffffff !important;
        }
      }

      // &:first-child {
      //   margin-right: 8px;
      // }
    }
  }

  sup {
    border: none;
    @include remove-box-shadow();
  }

  @include mq("xs") {
    // width: 72px;
    // min-width: 72px;
    max-width: 180px;
    // right: 24px;
  }
}

.version {
  transform: scale(1);
  @include prefix(transition, transform 0.3s);

  &.showSearch {
    transform: scale(0);
  }
}

.notificationsIcon {
  transform: scale(1);
  @include prefix(transition, transform 0.3s);

  &.showSearch {
    transform: scale(0);
  }
}

.menuIcon {
  transform-origin: center;
  transform: scale(1);
  @include prefix(transition, transform 0.3s);

  &.search {
    transform: scale(0) rotate(180deg);
  }
}

.route {
  transform: scale(1);
  @include prefix(transition, transform 0.3s);
  max-width: calc(100% - 64px);
  overflow: hidden;
  text-overflow: ellipsis;

  &.search {
    transform: scale(0);
  }
}

.popover {
  max-height: 400px;
  z-index: 900 !important;

  :global(.ant-popover-content) {
    max-height: 400px;
    width: 400px;
    border-radius: 4px;

    :global(.ant-popover-arrow) {
      border-color: $darkModeComponent !important;
    }

    :global(.ant-popover-inner) {
      max-height: 400px;
      position: relative;
      border-radius: 4px;

      :global(.ant-popover-title) {
        background: $darkModeComponent;
        color: rgba(255, 255, 255, 0.85);
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;

        > div {
          @include flexbox();
          @include justify-content(space-between);
          @include align-items(center);
        }

        &:global(.popover-footer) {
          position: absolute;
          bottom: -34px;
          width: 100%;
        }
      }

      :global(.ant-popover-inner-content) {
        padding: 0;
        max-height: calc(400px - 34px);
        overflow: auto;
        position: relative;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        :global(.ant-popover-title) {
          &:global(.popover-footer) {
            position: sticky;
            bottom: 0px;
            width: 100%;
            z-index: 1;
            background: #ffffff;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
          }
        }
      }
    }
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.header {
  @include flexbox();
  height: 64px;
  align-items: center;
  background: $primary;

  &:global(.lost) {
    background: $lost;
  }

  &:global(.hold) {
    background: $hold;
  }

  &:global(.closed-out) {
    background: $closedOut;
  }

  :global(button) {
    margin: 0 12px;
    border: none;
    outline: none;
  }
}

.title {
  font-size: 1rem;
  text-align: center;
  @include flex-grow(1);
  margin-bottom: 0;
  color: #ffffff;
  font-weight: 500;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stopLight {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  vertical-align: middle;
  margin-bottom: 3px;
  border: 1px solid #ffffff;
  display: inline-block;

  &.stop {
    background: red;
  }

  &.go {
    background: #5cd65c;
  }

  &.caution {
    background: yellow;
  }
}

.headerSalesmen {
  font-weight: 300;
  font-size: 70%;
  text-align: center;
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.scopeBadge {
  &:global(.ant-tag) {
    border-radius: 22px;
    width: 32px;
    text-align: center;
    display: inline-flex;
    @include justify-content(center);
    @include align-items(center);

    &.woNotCreated {
      opacity: 0.3;
    }
  }
}

.footer {
  background: #ffffff;
  @include zDepth0;
}

.mt8 {
  margin-top: 8px;
}

.sortableContainer {
  @include flexbox();
  @include flex(0 1 auto);
  @include flex-direction(row);
  @include flex-wrap(wrap);
  margin-right: -16px;
  margin-left: -16px;

  > div {
    width: 25%;

    @include mq("md") {
      width: 33.33%;
    }
    @include mq("sm") {
      width: 50%;
    }
    @include mq("xs", "max") {
      width: 100%;
    }

    &:global(.placeholder) {
      .scopeCard {
        background: $selectedRow !important;
        box-shadow: none !important;
      }
    }

    margin-bottom: 16px;
    padding-right: 8px;
    padding-left: 8px;
  }
}

.scopeCard {
  background: #ffffff;
  @include prefix(transition, background 0.3s);

  &.urgent {
    background: $urgent;
  }

  &.selected {
    background: $selectedRow;

    :global(.ant-card-head) {
      border-bottom: 1px solid #dcdcdc;
    }
  }

  :global(.ant-card-head) {
    @include prefix(transition, border-bottom 0.3s);
  }

  :global(.ant-card-cover) {
    img {
      width: 100%;
      max-height: 150px;
      min-height: 150px;
      object-fit: cover;
    }
  }

  :global(.ant-card-meta-title) {
    margin-bottom: 4px !important;
  }

  * {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
}

.flex {
  @include flexbox();
  @include align-items(center);
  @include justify-content(flex-start);
}

.dateStamp {
  position: absolute;
  top: 45px;
  left: 4px;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.85);
  z-index: 1;
  padding: 4px 8px;
  width: auto;
  border-radius: 6px;
  font-size: 10px;
}

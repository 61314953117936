@import "Styles/mixins.scss";

.reportTitle {
  font-weight: 800;
  color: rgba(0, 0, 0, 0.35);
}

.reportHeader {
  display: -ms-flexbox;
  display: -webkit-flexbox;
  display: flex;
  justify-content: flex-start;

  > .total {
    font-weight: bold;
    font-size: 26px;

    > small {
      font-size: 40%;
    }
  }
}

.listHeader {
  &:global(.ant-list-item) {
    position: relative;
    border-top: 4px solid rgba(0, 0, 0, 0.65);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-left: 24px;
    padding-right: 24px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06),
        0 1px 5px 0 rgba(0, 0, 0, 0.04), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
    }

    :global(.ant-list-item-meta-title) {
      font-weight: 600;
    }

    h4 {
      font-weight: 600;
    }
  }
}

.listItem {
  &:global(.ant-list-item) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.listRight {
  &:global(.ant-list-item-meta) {
    h4 {
      text-align: right;
    }
  }
}

.listTotal {
  &:global(.ant-list-item) {
    border-top: 3px #b3b3b3 double;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-left: 24px;
    padding-right: 24px;

    :global(.ant-list-item-meta-title) {
      font-weight: 600;
    }

    h4 {
      font-weight: 600;
    }
  }
}

.vDivider {
  border-left: 1px solid rgba(0, 0, 0, 0.54);
  margin-left: 24px;
  margin-right: 24px;
}

.hideSmDown {
  @include mq("sm") {
    display: none;
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.container {
  height: 100%;
  padding: 16px;
  background: $dark;

  :global(.ant-card-body) {
    height: 100%;
  }
}

.fifths {
  height: calc(25% - 50.75px);
  @include flexbox();
  @include flex-direction(column);
  @include justify-content(center);
}

.card {
  &:global(.ant-card) {
    overflow: hidden;
    height: 100%;
    @include zDepth1;
    background: $darkModeComponent !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    div,
    span {
      &:global(.ant-typography) {
        display: block;
        text-transform: uppercase;
        text-align: center;
        margin: 0 !important;

        &.fillLine {
          color: rgb(9, 75, 117) !important;
          color: #999999 !important;
        }

        &.offWhite {
          color: rgba(255, 255, 255, 0.7) !important;
        }

        &.poweredBy {
          font-size: 12px !important;
          display: inline-block;
          margin-right: 8px !important;
          color: rgba(255, 255, 255, 0.7) !important;
        }
      }
    }
  }
}

.poweredByContainer {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);

  > img {
    margin-top: -8px;
  }
}

.mycoLiveContainer {
  &:global(.ant-col) {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);

    > img {
      height: 80px;
    }
  }
}

@import "Styles/mixins.scss";

.mediaContainer {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
}

.headerContainer {
  padding: 0;
  border-top: none;
}

.imageBody {
  height: calc(100% - 64px) !important;
  position: absolute !important;
  overflow: hidden;
  padding: 0 !important;
  @include prefix(transition, height 0.3s);
  right: 0;
  left: 0;

  &.selecting {
    height: calc(100% - 64px - 53px) !important;
  }

  :global(.lazyload-wrapper) {
    margin-bottom: 12px;
    cursor: pointer;
  }
}

@import "Styles/variables.scss";
@import "Styles/mixins.scss";

.stageContainer {
  &:global(.ant-col) {
    .card {
      &:global(.ant-card) {
        overflow: hidden;
        height: 100%;
        @include zDepth1;
        background: $darkModeComponent !important;
        height: 100%;
        padding: 12px;
      }
    }

    &.two {
      .card {
        border-right: 10px solid #01c0c8;
      }
    }

    &.three {
      .card {
        border-right: 10px solid #fec107;
      }
    }

    &.four {
      .card {
        border-right: 10px solid rgb(16, 142, 233);
      }
    }

    &.five {
      .card {
        border-right: 10px solid #00c292;
      }
    }

    &.six {
      .card {
        border-right: 10px solid #ff7056;
      }
    }

    .card {
      &:global(.ant-card) {
        .stageTitle {
          text-align: left !important;
          color: rgba(255, 255, 255, 0.7) !important;
        }

        .stageNumber {
          text-align: left !important;
          font-size: 68px;
          margin: 0;
          color: #094b75 !important;
          color: #ffffff !important;
        }
      }
    }
  }
}

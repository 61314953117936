@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.header {
  @include flexbox();
  height: 64px;
  align-items: center;
  background: $primary;

  :global(button) {
    margin: 0 12px;
    border: none;
    outline: none;
    color: #ffffff !important;
    @include prefix(transition, color 0.3s, background 0.3s);
    width: 102px;

    &.leftActions {
      @include flexbox();
      @include justify-content(flex-start);
      padding-left: 0;
    }

    &.rightActions {
      margin-right: 12px;
      @include flexbox();
      @include justify-content(flex-end);
    }
  }
}

.title {
  font-size: 1rem;
  text-align: center;
  @include flex-grow(1);
  margin-bottom: 0;
  color: #ffffff;
  font-weight: 500;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header {
  border-top: 0;
  border-bottom: 1px solid #f0f0f0;
  padding: 12px;
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);

  .title {
    font-size: 13px;
    font-weight: 700;
    margin-left: 6px;
  }
}

@import "Styles/mixins.scss";
@import "Styles/variables.scss";

.border {
  border: 2px solid white;

  &.dark {
    border-color: #424242;
  }
}

.background {
  background-size: cover;
  background-position: center !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
  background-color: $dark;
}

.height50 {
  height: 50%;
}

.height100 {
  height: 100%;
}

.clickOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.videoContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 100%;
    object-fit: cover;
  }
}

.pdf {
  height: 100%;
  width: 100%;
  background: #ffffff;

  > div {
    height: 100%;
    width: 100%;
    background: $dark;
    padding: 8px;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include flex-direction(column);
    > img {
      max-width: 45px;
      display: block;
    }

    > div {
      width: 100%;
      text-align: center;
      color: #ffffff;
      font-size: 12px;
      margin-top: 8px;
    }
  }
}

.video {
  height: 100%;
  width: 100%;
  padding: 8px;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(column);

  > img {
    max-width: 45px;
    display: block;
  }

  > div {
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    margin-top: 8px;
  }
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

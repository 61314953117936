.breakdown {
  margin: 0.5in !important;
  width: 7.5in !important;
  display: none;
  font-size: 26px;

  @page {
    size: auto;
    margin: 0.5in !important;
    width: 7.5in !important;
  }

  .bpRow {
    border-bottom: 1px solid black;
    line-height: 48px;

    .col1 {
      width: 65%;
      display: inline-block;
      border-right: 2px solid black;
    }

    .col2 {
      width: 34%;
      display: inline-block;
      text-align: right;
    }
  }

  .exTitle {
    line-height: 48px;
    margin-top: 24px;

    .col1 {
      width: 60%;
      display: inline-block;
    }

    .col2 {
      width: 39%;
      display: inline-block;
      text-align: right;
    }
  }

  .expenseRow {
    border-bottom: 1px solid black;
    line-height: 48px;

    .col1 {
      width: 65%;
      display: inline-block;
      // border-right: 2px solid black;
    }

    .col2 {
      width: 34%;
      display: inline-block;
      text-align: right;
    }
  }

  @media print {
    display: block !important;

    html, body { 
      height: initial !important; 
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
     }
  }
}
.mobileContainer {
  padding: 0px;
  height: 100%;

  .balanceCardContainer {
    padding-left: 12px;
    padding-top: 12px;
    padding-right: 12px;
  }

  .transactionsContainer {
    height: calc(100% - 254px);
    background: #ffffff;
  }

  .carousel {
    margin-top: 16px;

    :global(.slick-list) {
      :global(.slick-track) {
        :global(.slick-slide) {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
  }
}
